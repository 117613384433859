import React, {useState, useEffect} from 'react'
import {onMessageListener} from '../Firebase';
import {toast} from "react-toastify";

const Notification = () => {
    const [notification, setNotification] = useState({title: '', body: ''});
    const ToastDisplay = () => {
        return (
            <div>
                <p><b>{notification?.title}</b></p>
                <p>{notification?.body}</p>
            </div>
        );
    };

    useEffect(() => {
        
        if (notification?.title) {
            toast(ToastDisplay, {
                type: "info"
            })
        }
    }, [notification])

    onMessageListener()
        .then((payload) => {
            setNotification({title: payload?.notification?.title, body: payload?.notification?.body});
        })
        .catch((err) => console.log('failed: ', err));
    return <></>
}

export default Notification