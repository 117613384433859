import React from 'react';
import {Link} from 'react-router-dom';

const NotFound: React.FC = () => {
    return (
        <main role="main" className={'text-center'}>
            <div>
                <h1>404 - ¡Página no encontrada!</h1>
                <Link to="/">Ir al inicio</Link>
            </div>
        </main>
    );
}
export default NotFound;
