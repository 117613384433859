import React, {useState} from "react";
import {useField, useFormikContext} from "formik";
import DatePicker, {registerLocale} from "react-datepicker";
import Moment from "moment";
import es from 'date-fns/locale/es';
import '../assets/styles/datePicker.css';

registerLocale('es', es)

const DatePickerField = ({...props}: { [x: string]: any; name: string }) => {
    const {setFieldValue, setFieldTouched} = useFormikContext();
    const [field] = useField(props);
    const [startDate, setStartDate] = useState(new Date());
    return (
        <DatePicker
            {...field}
            {...props}
            locale="es"
            selected={startDate}
            dateFormat="DD/MM/Y"
            onChange={val => {
                setStartDate(val!);
                setFieldValue(field.name, Moment(val).format("DD/MM/Y"));
            }}
            onChangeRaw={e => {
                setFieldTouched(field.name, true, true);
            }}
        />
    );
};

export default DatePickerField;