import React, {useEffect, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import {requestForToken} from "../Firebase";
import TokenService from "../services/token.service";
import {useLiveQuery} from "dexie-react-hooks";

const NavbarBottom: React.FC = () => {
    const location = useLocation();
    const [url, setUrl] = useState('');
    const token = useLiveQuery(() => TokenService.getLocalAccessToken());
    useEffect(() => {
        setUrl(location.pathname);
    }, [location]);

    const listHome = [
        /\/$/,
        /\/home$/,
        /\/categorias$/,
        /\/comercios$/,
        /\/beneficios$/,
        /\/convenios$/
    ];
    const handleNotification = () => {
        Notification.requestPermission()
            .then(async result => {
                if (result === 'granted') {
                    requestForToken();
                }
            })
            .catch(err => {
                console.log('Error: ', err);
            });
    }
    return (
        <>
            <div className="hide-me tab">
                <div className="tab__item">
                </div>
                <div className="tab__item">
                </div>
            </div>
            {token ?
                <div id={"tabBottom"} className="tab">
                    <div className="tab__item">
                        <Link to='/'
                              className={"nav__link" + (listHome.some(regx => regx.test(url)) ? " is-active" : "")}>
                            <i className="ico-route"></i>
                            <b>Explorar</b>
                        </Link>
                    </div>
                    <div className="tab__item">
                        <Link to='/perfil' className={"nav__link" + (url === "/perfil" ? " is-active" : "")}>
                            <i className="ico-profile"></i>
                            <b>Mi perfil</b>
                        </Link>
                    </div>
                    <div className="tab__item">
                        <Link to='/buscador' className={"nav__link" + (url === "/buscador" ? " is-active" : "")}>
                            <i className="ico-search"></i>
                            <b>Buscar</b>
                        </Link>
                    </div>
                </div>
                :
                <div id={"tabBottom"} className="tab">
                    <div className="tab__item">
                        <Link to='/'
                              className={"nav__link" + (listHome.some(regx => regx.test(url)) ? " is-active" : "")}>
                            <i className="ico-route"></i>
                            <b>Explorar</b>
                        </Link>
                    </div>
                    <div className="tab__item">
                        <Link to='/buscador' className={"nav__link" + (url === "/buscador" ? " is-active" : "")}>
                            <i className="ico-search"></i>
                            <b>Buscar</b>
                        </Link>
                    </div>
                </div>
            }
        </>
    );
}

export default NavbarBottom;