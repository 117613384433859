import {initializeApp} from "firebase/app";
import {getMessaging, getToken, onMessage} from "firebase/messaging";
import {saveTokenNotification} from "./services/personas.service";
import TokenService from "./services/token.service";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export const requestForToken = async () => {
    try {
        let currentToken = await getToken(messaging, {vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY});
        if (currentToken) {
            saveTokenNotification(currentToken).then(r => () => {
            });
        } else {
            // Show permission request UI
        }
    } catch (err) {
    }
};
export const onMessageListener = () =>
    new Promise<any>((resolve) => {
        onMessage(messaging, (payload) => {
            resolve(payload);
        });
    });
