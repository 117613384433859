import React, {useEffect, useState} from 'react';

import {useNavigate} from "react-router-dom";
import Loading from "./Loading";
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css";
import {getBeneficios, getCategorias} from "../services/empresas.service";
import {responseToast} from "../common/helpers";
import Categorias from "../types/categorias.type";
import {HandshakeOutlined} from "@mui/icons-material";
import Navbar from "./Navbar";
import Beneficios from "../types/beneficios.type";
import BeneficioCard from "./BeneficioCard";

const ListaBeneficios: React.FC = () => {
    const [loaded, setLoaded] = useState<boolean>(false);
    const [listaBeneficios, setListBeneficios] = useState<Beneficios[]>([]);
    const navigate = useNavigate();
    const [listaCategorias, setListaCategoria] = useState<Categorias[]>([]);
    const [idCategoria, setIdCategoria] = useState();

    const handleBack = () => {
        navigate(-1);
    };

    useEffect(() => {
        setLoaded(false);
        const getListaBeneficios = getBeneficios(idCategoria).then((data) => {
                return data;
            },
            (error) => {
                responseToast(error);
                return [];
            });
        const getAllCategoria = getCategorias().then((data) => {
                return data;
            },
            (error) => {
                responseToast(error);
                return [];
            });

        Promise.all([getListaBeneficios, getAllCategoria]).then(data => {
            setListBeneficios(data[0]);
            setListaCategoria(data[1]);
            setLoaded(true);
        })
    }, [idCategoria]);
    return (
        <>
            <Loading isLoaded={loaded}/>
            <Navbar/>
            <main role="main" className={'ai-fs'}>
                <section className="page page--benefits">
                    <div className="letterhead letterhead--default letterhead--column">
                        <div className="letterhead__actions">
                            <button className="action" onClick={handleBack}>
                                <i className="ico-back-circle"></i>
                            </button>
                        </div>
                        <div className="launch">
                            <HandshakeOutlined fontSize='large'/>
                            <h1 className="title">Beneficios</h1>
                            <h2 className="headline">
                                Aquí encontrarás los beneficios disponibles
                            </h2>
                        </div>
                    </div>
                    <div className="block block--round">
                        <div className="filters">
                            <Swiper
                                slidesPerView={'auto'}
                                spaceBetween={16}
                            >
                                <SwiperSlide
                                    role={'group'}
                                >
                                    <button className="tag disabled no-decoration">
                                        <i className="ico-filters"></i>
                                        <span>Filtros</span>
                                    </button>
                                </SwiperSlide>
                                <SwiperSlide
                                    role={'group'}
                                >
                                    <button
                                        onClick={() => {
                                            setIdCategoria(undefined)
                                        }}
                                        className={"no-decoration pointer tag " + (idCategoria === null || idCategoria === undefined ? 'is-active' : '')}>
                                        <span>Todo</span>
                                    </button>
                                </SwiperSlide>
                                {
                                    listaCategorias?.map((item) => {
                                        return (
                                            <SwiperSlide
                                                key={"categoria_" + item.nombre}
                                            >
                                                <button
                                                    className={'no-decoration  pointer tag ' + (item.id === idCategoria ? 'is-active' : '')}
                                                    onClick={() => {
                                                        setIdCategoria(item.id)
                                                    }}>
                                                    <span>{item.nombre}</span>
                                                </button>
                                            </SwiperSlide>
                                        );
                                    })
                                }
                            </Swiper>
                        </div>

                        {
                            listaBeneficios!.length > 0 ?
                                <div className="benefits mb-1">
                                    {listaBeneficios.map((item) => {
                                            return <BeneficioCard key={'beneficio-' + item.id} beneficio={item}/>
                                        }
                                    )}
                                </div>
                                :
                                <div className={'d-flex justify-contenet-center '}>
                                    <span>No hay comercios a mostrar</span>

                                </div>
                        }
                    </div>
                </section>
            </main>
        </>
    );
}
export default ListaBeneficios;
