import React, {FC, useEffect, useState} from "react";
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from "yup";

import AuthService from "../services/auth.service";
import {useNavigate, useParams} from 'react-router-dom';
import {toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Loading from "./Loading";

const RecoverPassword: FC = () => {
    let navigate = useNavigate();
    const params = useParams();
    const [loaded, setLoaded] = useState<boolean>(false);

    const initialValues: {
        password: string;
        confirmPassword: string;
    } = {
        password: "",
        confirmPassword: ""
    };

    const validationSchema = Yup.object().shape({
        password: Yup.string()
            .min(8, '¡Debe tener mínimo 8 caracteres!')
            .matches(
                /^(.*[A-Z].*)$/,
                "¡Debe contener al menos una letra mayúscula!"
            )
            .matches(
                /^(.*\d.*)$/,
                "¡Debe contener al menos un número!"
            )
            .matches(
                /^(.*[,.:;].*)$/,
                "¡Debe contener al menos un signo de puntuación ( , . : ; )!"
            )
            .required("¡Este campo es requerido!"),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref("password")], "¡Las contraseñas no coinciden!")
            .required("¡Este campo es requerido!")
    });

    const handleEyeClick = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        const inputTarget = event.currentTarget.getAttribute('data-target') ?? '';
        const passTarget = event.currentTarget.getAttribute('id') ?? '';
        const inputPass = (document.getElementById(inputTarget) as HTMLInputElement),
            togglePass = (document.getElementById(passTarget) as HTMLElement);
        if (inputPass!.type === 'password') {
            inputPass!.setAttribute('type', 'text')
            togglePass!.classList.add('is-active')
        } else {
            inputPass!.setAttribute('type', 'password')
            togglePass!.classList.remove('is-active')
        }
    };

    const handleRecover = (formValue: { password: string; confirmPassword: string }) => {
        const {password, confirmPassword} = formValue;
        const dominio = params.domi ?? 'kandy';
        const token = params.token ?? '';
        AuthService.changePassword(password, confirmPassword, dominio, token)
            .then(
                () => {
                    toast.success("Se ha cambiado su contraseña correctamente", {
                        position: "bottom-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    navigate("/");
                },
                (error) => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    const type = error.response.status >= 500 ? 'error' : 'warning';
                    toast.warn(resMessage, {
                        type: type,
                        position: "bottom-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            );
    };

    useEffect(() => {
        const dominio = params.domi ?? 'kandy';
        const token = params.token ?? '';
        AuthService.validateToken(dominio, token)
            .then(
                () => {
                    setLoaded(true);
                },
                (error) => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    setLoaded(true);
                    const type = error.response.status >= 500 ? 'error' : 'warning';
                    toast(resMessage, {
                        type: type,
                        position: "bottom-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    navigate('/');
                }
            );
    }, [])

    return (
        <main role="main">
            <Loading isLoaded={loaded}/>
            <section className="login">
                <h1 className="title">Cambiar contraseña</h1>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleRecover}
                >
                    <Form
                        className="form"
                    >
                        <div className="form__field is-password">
                                <span className="private" id="privatePassword" data-target="password"
                                      onClick={handleEyeClick}>
                                    <i className="ico-eye"></i>
                                </span>
                            <Field name="password" className="input input--shadow" id="password" type="password"
                                   autoComplete="off"
                                   placeholder="Escribe tu nueva contraseña"/>
                        </div>
                        <ErrorMessage
                            name="password"
                            component="div"
                            className="alert alert-danger"
                        />
                        <div className="form__field is-password">
                                <span className="private" id="privateConfirm" data-target="confirmPassword"
                                      onClick={handleEyeClick}>
                                    <i className="ico-eye"></i>
                                </span>
                            <Field name="confirmPassword" className="input input--shadow" id="confirmPassword"
                                   type="password"
                                   autoComplete="off"
                                   placeholder="Confirma tu nueva contraseña"/>
                        </div>
                        <ErrorMessage
                            name="confirmPassword"
                            component="div"
                            className="alert alert-danger"
                        />
                        <div className="form__field button-container">
                            <button className="btn" type="submit">Ingresar</button>
                        </div>
                    </Form>
                </Formik>
                <div>
                    Recuerda que la nueva contraseña debe tener:
                    <ul className="text-start list-style-closed">
                        <li>Mínimo 8 caracteres</li>
                        <li>Al menos una letra mayúscula</li>
                        <li>Al menos un número</li>
                        <li>Al menos un signo de puntuación ( , . : ; )</li>
                    </ul>
                </div>
            </section>
        </main>
    );
}

export default RecoverPassword;
