import React, {useEffect, useState} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import AuthService from "../services/auth.service";
import LogoutIcon from '@mui/icons-material/Logout';
import LoginIcon from '@mui/icons-material/Login';
import TokenService from "../services/token.service";
import {requestForToken} from "../Firebase";
import {useLiveQuery} from "dexie-react-hooks";

const Navbar: React.FC = () => {
    const location = useLocation();
    const [url, setUrl] = useState('');
    const navigate = useNavigate();

    const handleLogout = () => {
        AuthService.logout();
        navigate('/');
    }

    const handleLogin = () => {
        navigate('/login');
    }

    const token = useLiveQuery(() => TokenService.getLocalAccessToken());
    const logo = useLiveQuery(() => TokenService.getLogo());

    const handleNotification = () => {
        Notification.requestPermission()
            .then(async result => {
                if (result === 'granted') {
                    requestForToken();
                }
            })
            .catch(err => {
                console.log('Error: ', err);
            });
    }

    useEffect(() => {
        setUrl(location.pathname);
    }, [location]);

    const listHome = [
        /\/$/,
        /\/home$/,
        /\/categorias$/,
        /\/comercios$/,
        /\/beneficios$/,
        /\/convenios$/
    ];

    return (
        <header className="header">
            <div className="center">
                <a className="brand" href="/">
                    <img src={logo} alt="logo"/>
                </a>
                <nav className="nav">
                    <ul className="nav__items">
                        <li className="nav__item">
                            <Link to='/'
                                  className={"nav__link" + (listHome.some(regx => regx.test(url)) ? " is-active" : "")}>
                                <i className="ico-route"></i>
                                <b>Explorar</b>
                            </Link>
                        </li>
                        {
                            (token) ?
                                <>
                                    <li className="nav__item">
                                        <Link to='/perfil'
                                              className={"nav__link" + (url === "/perfil" ? " is-active" : "")}>
                                            <i className="ico-profile"></i>
                                            <b>Mi perfil</b>
                                        </Link>
                                    </li>
                                    <li className="nav__item">
                                        <button onClick={handleNotification} className="nav__link">
                                            <i className="ico-notify"></i>
                                            <b>Notificaciones</b>
                                        </button>
                                    </li>
                                </>
                                :
                                <></>
                        }
                        <li className="nav__item">
                            <div className="form--search">
                                <div className="form__field">
                                    <input className="input" id="search" onClick={() => {
                                        navigate('/buscador/')
                                    }} type="text" name="search" autoComplete={'off'} required/>
                                    <label className="label" htmlFor="search"><i className="ico-search"></i>
                                        <b>Buscar beneficios</b>
                                    </label>
                                </div>
                            </div>
                        </li>
                    </ul>
                </nav>
                {
                    (token) ?
                        <button className="action" onClick={handleLogout}>
                            <LogoutIcon/>
                        </button>
                        :
                        <button className="action" onClick={handleLogin}>
                            <LoginIcon/>
                        </button>
                }
            </div>
        </header>
    );
}

export default Navbar;