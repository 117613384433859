import {toast} from "react-toastify";

export const responseToast = (result: any) => {
    if(result.code !== "ERR_CANCELED"){
        const resMessage =
            (result.response &&
                result.response.data &&
                result.response.data.message) ||
            result.message ||
            result.toString();
        const typeToast = result.response?.status >= 500 ? 'error' : 'warning';
        toast(resMessage, {
            type: typeToast,
            position: "bottom-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
};


