import TokenService from "./token.service";
import api from "./api";
import config from "./conf";

class AuthService {
    login(usuario: string, password: string, dominio: string) {
        const loginFormData = new FormData();
        loginFormData.append("username", usuario);
        loginFormData.append("password", password);
        loginFormData.append("dominio", dominio);

        return api
            .post("?controller=personas&action=login", loginFormData, {
                withCredentials: true
            })
            .then((response) => {
                if (response.data.jwt) {
                    TokenService.setUser(response.data)
                }
            });
    };

    register(name: string, last_name: string, username: string, password: string, dominio: string) {
        const loginFormData = new FormData();
        loginFormData.append("name", name);
        loginFormData.append("last_name", last_name);
        loginFormData.append("username", username);
        loginFormData.append("password", password);
        return api
            .post("?controller=personas&action=registerEmail", loginFormData, {
                withCredentials: true
            })
            .then((response) => {
                if (response.data.jwt) {
                    TokenService.setUser(response.data)
                }
            })
    };

    changePassword(password: string, confirmPassword: string, dominio: string, token: string) {
        const loginFormData = new FormData();
        loginFormData.append("password", password);
        loginFormData.append("confirmPassword", confirmPassword);
        loginFormData.append("dominio", dominio);
        loginFormData.append("token", token);

        return api
            .post("?controller=personas&action=changePassword", loginFormData);
    };

    forgotPassword(email: string, dominio: string, token: string) {
        const loginFormData = new FormData();
        loginFormData.append("email", email);
        loginFormData.append("dominio", dominio);
        loginFormData.append("token", token);

        return api
            .post("?controller=personas&action=forgotPassword", loginFormData);
    };

    validateToken(dominio: string, token: string) {
        const loginFormData = new FormData();
        loginFormData.append("dominio", dominio);
        loginFormData.append("token", token);

        return api
            .post("?controller=personas&action=validateToken", loginFormData);
    };

    loginFacebook(data: any) {
        return fetch(config.api_url + '?controller=personas&action=loginFacebook', {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            body: JSON.stringify(data)
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
            })
            .then((result) => {
                if (result.jwt) {
                    TokenService.setUser(result)
                }
            })
    };

    loginGoogle(data: any) {
        return fetch(config.api_url + '?controller=personas&action=loginGoogle', {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            body: JSON.stringify(data)
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
            })
            .then((result) => {
                if (result.jwt) {
                    TokenService.setUser(result)
                }
            })
    };

    logout() {
        TokenService.removeUser()
    };
}

export default new AuthService();