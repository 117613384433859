import loadingImg from '../assets/images/brand-vertical.png';
import '../assets/styles/loading.css';

const Loading = (props: { isLoaded: boolean, type?: number, width?: number, extraClass?:string }) => {
    const className = props.isLoaded ? "loading-container loaded" : "loading-container";

    let typeClass = '';
    if (props.type == 1) {
        typeClass = ' transparent-background ';
    } else if (props.type == 2) {
        typeClass = ' white-background '
    }

    const widthClass = props.width == 1 ? ' w100 ' : '';
    return (
        <div className={className + typeClass + widthClass+' '+props.extraClass}>
            <img className="loading-img" src={loadingImg} alt="loading-gif"/>
        </div>
    );
}

export default Loading;