import React, {useEffect, useRef, useState} from "react";
import "swiper/css";
import Navbar from "./Navbar";
import NavbarBottom from "./NavbarBottom";
import {ModalPerfil} from "./ModalPerfil";
import {ErrorMessage, Field, Form, Formik, FormikProps} from "formik";
import * as Yup from "yup";
import DatePickerField from "./DatePickerField";
import {Id, toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import {
    getCategorias,
    getConvenios,
    updadateCategorias,
    updadateConvenios,
    updateProfile
} from "../services/personas.service";
import {
    getCategorias as getCategoriasEmpresas,
    getConvenios as getConveniosEmpresas
} from "../services/empresas.service";
import TokenService from "../services/token.service";
import Moment from "moment";
import Loading from "./Loading";
import {responseToast} from "../common/helpers";
import {StoreOutlined, WatchLaterOutlined} from "@mui/icons-material";
import {Badge} from "./Badge";
import Categoria from "../types/categorias.type";
import CategoriaPersona from "../types/categorias-personas.type";
import ConvenioEmpresa from "../types/convenios-empresas.type";
import ConvenioEmpresaPersona from "../types/convenios-empresas-personas.type";
import {base64StringToBlob, createObjectURL} from "blob-util";
import {Tooltip} from "@mui/material";
import profileDummy from "../assets/images/profile-dummy.jpeg";
import funcionGustos from "../assets/images/funcion-gustos.svg";
import funcionBeneficios from "../assets/images/funcion-beneficios.svg";
import {useLiveQuery} from "dexie-react-hooks";

const Profile: React.FC = () => {
    const currentUser = useLiveQuery(() => TokenService.getUser());
    const userData = currentUser?.data ?? {
        foto_perfil: null,
        nombre: null,
        apellido: null,
        fecha_nacimiento: null,
        telefono_particular: null,
        nombre_completo: null
    };
    const [showModalTaste, setShowModalTaste] = useState<boolean>(false);
    const [showModalProfile, setShowModalProfile] = useState<boolean>(false);
    const [showModalConv, setShowModalConv] = useState<boolean>(false);
    const [loaded, setLoaded] = useState<boolean>(false);
    const toastId = useRef<Id | null>(null);
    const [image, setImage] = useState(userData.foto_perfil ?? profileDummy);
    const [misConvenios, setMisConvenios] = useState<ConvenioEmpresaPersona[]>([]);
    const [misCategorias, setMisCategorias] = useState<CategoriaPersona[]>([]);
    const [categorias, setCategorias] = useState<Categoria[]>([]);
    const [convenios, setConvenios] = useState<ConvenioEmpresa[]>([]);
    const [categoriasBase, setCategoriasBase] = useState<Categoria[]>([]);
    const [conveniosBase, setConveniosBase] = useState<ConvenioEmpresa[]>([]);
    const [hayCategorias, setHayCategorias] = useState(false);
    const [hayMisCategorias, setHayMisCategorias] = useState(false);
    const formRef = useRef<FormikProps<any>>(null);
    let navigate = useNavigate();
    let listaCategorias: any[] = [];
    let listaConvenios: any[] = [];

    const initialValues: {
        nombre: string;
        apellido: string;
        fecha_nacimiento: string;
        foto_perfil: string;
        telefono_particular: string;
    } = {
        nombre: userData.nombre ? userData.nombre : "",
        apellido: userData.apellido ? userData.apellido : "",
        fecha_nacimiento: userData.fecha_nacimiento ? Moment(userData.fecha_nacimiento).format('DD/MM/y') : "",
        foto_perfil: userData.foto_perfil ? userData.foto_perfil : "",
        telefono_particular: userData.telefono_particular ? userData.telefono_particular : "",
    };

    const validationSchema = Yup.object().shape({
        nombre: Yup.string().required("¡Este campo es requerido!"),
        apellido: Yup.string().required("¡Este campo es requerido!"),
        fecha_nacimiento: Yup.string()
            .matches(/^\d{2}\/\d{2}\/\d{4}$/, "¡Fecha no válida!"),
        telefono_particular: Yup.string()
            .min(6, "¡Teléfono inválido!"),
    });

    const handleUpdateProfile = (formValue: {
        nombre: string;
        apellido: string;
        fecha_nacimiento: string;
        foto_perfil: string;
        telefono_particular: string;
    }) => {
        setLoaded(false);
        toastId.current = null;
        const {
            nombre,
            apellido,
            fecha_nacimiento,
            foto_perfil,
            telefono_particular
        } = formValue;
        updateProfile(nombre, apellido, fecha_nacimiento, foto_perfil, telefono_particular).then(() => {
                if (toastId.current !== null) {
                    toast.dismiss(toastId.current);
                }
                toast.success('Actualizado correctamente', {
                    position: "bottom-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                navigate('/perfil');
                setShowModalProfile(false);
                setLoaded(true);
            },
            (error) => {
                setLoaded(true);
                responseToast(error);
            }
        );
    };

    const handleUpdateCategorias = () => {
        setLoaded(false);
        updadateCategorias(listaCategorias).then((resp) => {
            toast.success('Actualizado correctamente', {
                position: "bottom-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            navigate(0);
        }, (error) => {
            setLoaded(true);
            responseToast(error);
        });
    };

    const handleUpdateConvenios = () => {
        setLoaded(false);
        updadateConvenios(listaConvenios).then((resp) => {
            toast.success('Actualizado correctamente', {
                position: "bottom-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            navigate(0);
        }, (error) => {
            setLoaded(true);
            responseToast(error);
        });
    };

    const handleClickCategoria = (event: React.MouseEvent) => {
        const target = event.currentTarget;
        target.classList.toggle('is-active');
        const id = target.getAttribute('data-id') ?? -1;
        const index = listaCategorias.indexOf(id);
        if (index === -1) {
            listaCategorias.push(id)
        } else {
            listaCategorias.splice(index, 1);
        }
    };

    const handleClickConvenio = (event: React.MouseEvent) => {
        const target = event.currentTarget;
        target.classList.toggle('is-active');
        const id = target.getAttribute('data-id') ?? -1;
        const index = listaConvenios.indexOf(id);
        if (index === -1) {
            listaConvenios.push(id)
        } else {
            listaConvenios.splice(index, 1);
        }
    };

    const handleFileChange = (e: any) => {
        setImage(URL.createObjectURL(e.target.files[0]));
    };
    
    useEffect(() => {
        setLoaded(false);
        const getMyConvenios = getConvenios().then((data) => {
                return data;
            },
            (error) => {
                responseToast(error);
                return [];
            });

        const getEmpresasConvenios = getConveniosEmpresas().then((data) => {
                return data;
            },
            (error) => {
                responseToast(error);
                return [];
            });

        const getMyCategorias = getCategorias().then((data) => {
                return data;
            },
            (error) => {
                responseToast(error);
                return [];
            });

        const getEmpresasCategorias = getCategoriasEmpresas().then((data) => {
                return data;
            },
            (error) => {
                responseToast(error);
                return [];
            });

        Promise.all([getMyConvenios, getMyCategorias, getEmpresasCategorias, getEmpresasConvenios]).then(data => {
            setMisConvenios(data[0]);
            setMisCategorias(data[1]);
            setCategoriasBase(data[2]);
            setCategorias(data[2]);
            setConvenios(data[3]);
            setHayMisCategorias(!(data[1].length == 0));
            setHayCategorias(!(data[2].length == 0));
            setLoaded(true);
        })
    }, []);

    return (
        <>
            <Loading isLoaded={loaded}/>
            <Navbar/>
            <main role="main" className={'ai-fs'}>
                <section className="page page--profile">
                    <div className="letterhead">
                        <div className="regards text-start">
                            <h1>{userData.nombre_completo}</h1>
                            <h2 className="headline">Completa tu perfil y recibe beneficios especiales</h2>
                            <br/>
                            <span className="link" onClick={() => {
                                setShowModalProfile(true)
                            }}>
                                Completar perfil
                            </span>
                        </div>
                        <div className="profile profile--small">
                            <div className="profile__avatar mb-0">
                                <img src={userData.foto_perfil ?? profileDummy} alt="avatar"/>
                            </div>
                        </div>
                    </div>
                    <div className="block block--round flex-grow-1">
                        <h4 className="subhead subhead--icon color-benefits">
                            <i className="ico-star"></i>
                            <b>Tus conexiones</b>
                        </h4>
                        <div className="layout layout--connection">
                            {
                                misConvenios.map((item) => {
                                    let image;
                                    let extraClass = '';
                                    if (item.convenio_empresa?.convenio?.imagen != null && item.convenio_empresa?.convenio?.imagen.length > 0) {
                                        let blob = base64StringToBlob(item.convenio_empresa?.convenio?.imagen);
                                        const blobUrl = createObjectURL(blob);
                                        image =
                                            <img src={blobUrl} title={item.convenio_empresa?.convenio?.nombre}
                                                 alt={item.convenio_empresa?.convenio?.nombre}/>
                                    } else {
                                        image = <><StoreOutlined fontSize={"inherit"}/><b
                                            className={'font-16'}>{item.convenio_empresa?.convenio?.nombre}</b></>
                                        extraClass = ' font-64';
                                    }
                                    return (
                                        <Tooltip key={"company-" + item.id}
                                                 title={item.convenio_empresa?.convenio?.nombre ?? ''} arrow>
                                            <a
                                                className={"pointer card card--company " + extraClass}
                                                href={"/convenios/" + item.ben_convenios_empresas_id}
                                            >
                                                {image}
                                            </a>
                                        </Tooltip>
                                    )
                                })
                            }
                        </div>
                        <span className="link" onClick={() => {
                            setShowModalConv(true)
                            setConveniosBase(convenios)
                        }}>Personalizar mis conexiones</span>
                        <br/>
                        <br/>
                        <h4 className="subhead subhead--icon color-likes">
                            <i className="ico-love"></i>
                            <b>Tus gustos</b>
                        </h4>
                        {hayCategorias ?
                            <>
                                {hayMisCategorias ?
                                    <div className="badges">
                                        {
                                            misCategorias.map((item, index) => {
                                                return (
                                                    <Badge
                                                        key={index}
                                                        id={item.categoria_id}
                                                        nombre={item.nombre}
                                                        activo={true}
                                                        onClick={() => {
                                                            navigate('/categorias/' + item.categoria_id)
                                                        }}
                                                    />

                                                )
                                            })
                                        }
                                        <Badge
                                            icon={'ico-plus'}
                                            activo={false}
                                            class={'badge--circle'}
                                            onClick={() => {
                                                setShowModalTaste(true)
                                                setCategoriasBase(categorias)
                                            }}
                                        />
                                    </div>
                                    :
                                    <>
                                        <h2 className="headline">
                                            Cuéntanos sobre tus intereses y recibe beneficios especiales para ti.
                                        </h2>
                                        <br/>
                                    </>
                                }
                                <br/>
                                <span className="link" onClick={() => {
                                    setShowModalTaste(true)
                                    setCategoriasBase(categorias)
                                }}>Personalizar mis gustos</span>
                            </>
                            :
                            <span className="d-flex">Pronto podrás personalizar tus gustos &nbsp; <WatchLaterOutlined/> </span>
                        }
                    </div>
                    <ModalPerfil.Frame
                        show={showModalProfile}
                        handleClose={() => {
                            setShowModalProfile(false)
                        }}
                    >
                        <ModalPerfil.LetterHead>
                            <h1 className="title">
                                Puedes modificar tus datos
                            </h1>
                            <img className="profile__avatar" src={image} alt="avatar"/>
                        </ModalPerfil.LetterHead>
                        <ModalPerfil.Content>
                            <Formik
                                innerRef={formRef}
                                initialValues={initialValues}
                                validationSchema={validationSchema}
                                onSubmit={handleUpdateProfile}
                                enableReinitialize
                            >
                                {({isSubmitting, handleChange, handleBlur, values, setFieldValue}) => (
                                    <Form
                                        id="profileForm"
                                        className="form"
                                    >
                                        <div className="form__field">
                                            <label htmlFor="nombre">Nombre</label>
                                            <Field
                                                type="text"
                                                name="nombre"
                                                className="input input--shadow"
                                                autoComplete="off"
                                                placeholder="Escribe tu nombre"
                                            />
                                            <ErrorMessage name="nombre"/>
                                        </div>
                                        <div className="form__field">
                                            <label htmlFor="apellido">Apellido</label>
                                            <Field
                                                type="text"
                                                name="apellido"
                                                className="input input--shadow"
                                                autoComplete="off"
                                                placeholder="Escribe tu apellido"
                                            />
                                            <ErrorMessage name="apellido"/>
                                        </div>
                                        <div className="form__field">
                                            <label htmlFor="fecha_nacimiento">Fecha Nacimiento</label>
                                            <DatePickerField
                                                name="fecha_nacimiento"
                                                className="input input--shadow"
                                                showYearDropdown
                                                scrollableYearDropdown
                                                maxDate={new Date()}
                                                placeholderText="Seleccione tu fecha de Nacimiento"
                                            />
                                            <ErrorMessage name="fecha_nacimiento"/>
                                        </div>
                                        <div className="form__field">
                                            <label htmlFor="tele">Teléfono Particular</label>
                                            <Field
                                                type="text"
                                                name="telefono_particular"
                                                className="input input--shadow"
                                                autoComplete="off"
                                                placeholder="Escribe tu número telefónico"
                                            />
                                            <ErrorMessage name="telefono_particular"/>
                                        </div>
                                        <div className="form__field">
                                            <label htmlFor="foto">Imagen de Perfil</label>
                                            <input
                                                name="foto_perfil"
                                                type="file"
                                                className="input input--shadow"
                                                accept="image/pjpeg,image/jpeg,image/jpg,image/png,image/x-png"
                                                onChange={val => {
                                                    handleFileChange(val);
                                                    setFieldValue('foto_perfil', val.target.files![0]);
                                                }}
                                            />
                                            <ErrorMessage name="foto_perfil"/>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </ModalPerfil.Content>
                        <ModalPerfil.Footer>
                            <div className="button-container">
                                <button
                                    className="btn"
                                    type="submit"
                                    form="profileForm"
                                    disabled={!(formRef.current?.isValid)}
                                >
                                    Continuar
                                </button>
                            </div>
                        </ModalPerfil.Footer>
                    </ModalPerfil.Frame>
                    <ModalPerfil.Frame
                        show={showModalTaste}
                        handleClose={() => {
                            setShowModalTaste(false)
                            setCategoriasBase([])
                        }}
                        type={1}
                    >
                        <ModalPerfil.LetterHead>
                            <img src={funcionGustos} alt="Gustos"/>
                            <strong className="color-likes">Descubre tus Beneficios</strong>
                            <h1 className="title">
                                Selecciona los temas que te gusten para darte beneficios ideales para ti.
                            </h1>
                        </ModalPerfil.LetterHead>
                        <ModalPerfil.Content>
                            <div id='listaCategorias' className="badges">
                                {
                                    categoriasBase.map((item, index) => {
                                        const categoriaEncontrada = misCategorias.find((cat) => cat.categoria_id == item.id);
                                        const activo = categoriaEncontrada != undefined;
                                        if (activo) {
                                            listaCategorias.push(item.id);
                                        }
                                        return (
                                            <Badge
                                                key={index}
                                                id={item.id}
                                                nombre={item.nombre}
                                                activo={activo}
                                                onClick={handleClickCategoria}
                                            />
                                        );
                                    })
                                }
                            </div>
                        </ModalPerfil.Content>
                        <ModalPerfil.Footer>
                            <div className="button-container">
                                <button className="btn" onClick={handleUpdateCategorias}>
                                    Continuar
                                </button>
                            </div>
                        </ModalPerfil.Footer>
                    </ModalPerfil.Frame>
                    <ModalPerfil.Frame
                        show={showModalConv}
                        handleClose={() => {
                            setShowModalConv(false)
                            setConveniosBase([])
                        }}
                        type={0}
                    >
                        <ModalPerfil.LetterHead>
                            <img src={funcionBeneficios} alt="beneficios"/>
                            <strong className="color-benefits">Descubre tus Beneficios</strong>
                            <h1 className="title">Selecciona a que estas afiliado y podrás ver los beneficios que hay
                                para ti.</h1>
                        </ModalPerfil.LetterHead>
                        <ModalPerfil.Content>
                            <div id='listaConvenios' className="badges">
                                {
                                    conveniosBase.map((item, index) => {
                                        const convenioEncontrada = misConvenios.find((conv) => conv.ben_convenios_empresas_id == item.id);
                                        const activo = convenioEncontrada != undefined;
                                        let image;
                                        let extraClass = '';
                                        if (item.convenio?.imagen != null && item.convenio.imagen.length > 0) {
                                            let blob = base64StringToBlob(item.convenio.imagen);
                                            const blobUrl = createObjectURL(blob);
                                            image = <img src={blobUrl} title={item.convenio.nombre}
                                                         alt={item.convenio.nombre}/>
                                        } else {
                                            image = <><StoreOutlined fontSize={"inherit"}/><b
                                                className={'font-16'}>{item.convenio?.nombre}</b></>
                                            extraClass = ' font-64';
                                        }
                                        if (activo) {
                                            listaConvenios.push(item.id);
                                        }
                                        return (
                                            <Tooltip key={"company-" + item.id} title={item.convenio?.nombre ?? ''}
                                                     arrow>
                                        <span
                                            onClick={handleClickConvenio}
                                            data-id={item.id}
                                            className={"pointer card card--company " + (activo ? 'is-active ' : '') + extraClass}
                                        >
                                            {image}
                                        </span>
                                            </Tooltip>
                                        );
                                    })
                                }
                            </div>
                        </ModalPerfil.Content>
                        <ModalPerfil.Footer>
                            <div className="button-container">
                                <button className="btn" onClick={handleUpdateConvenios}>Continuar
                                </button>
                            </div>
                        </ModalPerfil.Footer>
                    </ModalPerfil.Frame>
                    <NavbarBottom/>
                </section>
            </main>
        </>
    );
};

export default Profile;