import React, {FC, useState} from "react";
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from "yup";

import AuthService from "../services/auth.service";
import {useNavigate, useSearchParams} from 'react-router-dom';
import {toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {GoogleReCaptchaProvider} from 'react-google-recaptcha-v3';
import GoogleRecaptchaComponent from "./GoogleRecaptchaComponent";
import Loading from "./Loading";


const ForgotPassword: FC = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [loaded, setloaded] = useState(true);
    const initialValues: {
        email: string,
        token: string
    } = {
        email: "",
        token: ""
    };
    const validationSchema = Yup.object().shape({
        email: Yup.string().email("¡Debe ingresar un email válido!").required("¡Este campo es requerido!"),
    });

    const handleForgotPassword = (formValue: { email: string, token: string }) => {
        setloaded(false);
        const {email, token} = formValue;
        const dominio = searchParams.get("domi") ?? 'kandy';
        AuthService.forgotPassword(email, dominio, token).then(
            () => {
                toast.success("Se ha enviado un email para recuperar su contraseña", {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
                navigate("/");
            },
            (error) => {
                setloaded(true);
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                const type = error.response.status >= 500 ? 'error' : 'warning';
                toast(resMessage, {
                    type: type,
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        );
    };

    return (
        <main role="main">
            <Loading isLoaded={loaded}/>
            <section className="login">
                <h1 className="title">Ingrese su email para recuperar la contraseña</h1>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleForgotPassword}
                >
                    <Form
                        className="form"
                    >
                        <div className="form__field">
                            <Field name="email" className="input input--shadow" id="email" type="text"
                                   autoComplete="off"
                                   placeholder="Escribe tu email"/>
                        </div>
                        <ErrorMessage
                            name="email"
                            component="div"
                            className="alert alert-danger"
                        />
                        <GoogleReCaptchaProvider
                            language="es-419"
                            reCaptchaKey="6Ld-WcMgAAAAAIemQZHZYlct7yM8UtW1SBepUuR2">
                            <GoogleRecaptchaComponent/>
                        </GoogleReCaptchaProvider>
                    </Form>
                </Formik>
            </section>
        </main>
    );
}

export default ForgotPassword;