import React, {useEffect, useState} from "react";
import AuthService from "../services/auth.service";
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import Navbar from "./Navbar";
import NavbarBottom from "./NavbarBottom";
import TokenService from "../services/token.service";
import LogoutIcon from "@mui/icons-material/Logout";
import Loading from "./Loading";
import {responseToast} from "../common/helpers";
import {getBeneficios, getBeneficiosDelDia, getCategorias} from "../services/empresas.service";
import Categoria from "../types/categorias.type";
import Beneficios from "../types/beneficios.type";
import {useNavigate} from "react-router-dom";
import BeneficioCard from "./BeneficioCard";
import LoginIcon from "@mui/icons-material/Login";
import {Pagination} from "swiper";
import {useLiveQuery} from "dexie-react-hooks";

const Home: React.FC = () => {
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState<boolean>(false);
    const [categorias, setCategorias] = useState<Categoria[]>([]);
    const [destacados, setDestacados] = useState<Beneficios[]>([]);
    const [conexiones, setConexiones] = useState<Beneficios[]>([]);
    const [beneficiosDelDia, setBeneficiosDelDia] = useState<Beneficios[]>([]);
    const token = useLiveQuery(() => TokenService.getLocalAccessToken());
    const logo = useLiveQuery(() => TokenService.getLogo());

    useEffect(() => {
        setLoaded(false);
        const getCategoriasEmpresa = getCategorias().then((data) => {
                return data;
            },
            (error) => {
                responseToast(error);
                return [];
            });

        const getBeneficiosDestacadosEmpresa = getBeneficios(null, true, null, null, undefined, 10).then((data) => {
                return data;
            },
            (error) => {
                responseToast(error);
                return [];
            });
        const getBeneficiosDelDiaEmpresa = getBeneficiosDelDia().then((data) => {
                return data;
            },
            (error) => {
                responseToast(error);
                return [];
            });

        Promise.all([getCategoriasEmpresa, getBeneficiosDestacadosEmpresa, getBeneficiosDelDiaEmpresa]).then(data => {
            setCategorias(data[0]);
            setDestacados(data[1]);
            setBeneficiosDelDia(data[2]);
            setLoaded(true);
        })
    }, []);

    useEffect(() => {
        if (token) {
            setLoaded(false);
            const getBeneficiosConexiones = getBeneficios(null, false, null, true, undefined, 10).then((data) => {
                    return data;
                },
                (error) => {
                    responseToast(error);
                    return [];
                });
            Promise.all([getBeneficiosConexiones]).then(data => {
                setConexiones(data[0]);
                setLoaded(true);
            });
        }
    }, [token]);

    return (
        <>
            <Loading isLoaded={loaded}/>
            <Navbar/>
            <main role="main">
                <section className="home">
                    <div className="home__header">
                        {
                            (token) ?
                                <button className="setting pointer" onClick={() => {
                                    AuthService.logout();
                                    window.location.reload()
                                }}>
                                    <LogoutIcon/>
                                </button>
                                :
                                <button className="setting pointer" onClick={() => {
                                    navigate('/login')
                                }}>
                                    <LoginIcon/>
                                </button>
                        }
                        <a className="brand" href="/">
                            <img src={logo} alt="logo"/>
                        </a>
                    </div>

                    {
                        destacados.length > 0 ?
                            <div className="benefits">
                                <h1 className={'mb-1'}>¡Destacados! 🌟</h1>
                                <Swiper
                                    slidesPerView={"auto"}
                                    spaceBetween={30}
                                    pagination={{
                                        clickable: true,
                                    }}
                                    className={"swiper"}
                                    navigation={true}
                                    modules={[Pagination]}
                                    loop={true}
                                >
                                    {destacados.map((item) => {
                                        return (
                                            <SwiperSlide
                                                key={"beneficio_destacado_" + item.id}
                                                className={"swiper-slide-auto"}
                                            >
                                                <BeneficioCard key={'beneficio-' + item.id} beneficio={item}/>
                                            </SwiperSlide>
                                        );
                                    })}
                                </Swiper>
                            </div>
                            :
                            <></>
                    }

                    {
                        beneficiosDelDia.length > 0 ?
                            <div className="benefits">
                                <h1 className={'mb-1'}>¡Beneficios del día! 📅</h1>
                                <Swiper
                                    slidesPerView={"auto"}
                                    spaceBetween={30}
                                    pagination={{
                                        clickable: true,
                                    }}
                                    className={"swiper"}
                                    navigation={true}
                                    modules={[Pagination]}
                                    loop={true}
                                >
                                    {beneficiosDelDia.map((item) => {
                                        return (
                                            <SwiperSlide
                                                key={"beneficio_del_dia_" + item.id}
                                                className={"swiper-slide-auto"}
                                            >
                                                <BeneficioCard key={'beneficio-dia-' + item.id} beneficio={item}/>
                                            </SwiperSlide>
                                        );
                                    })}
                                </Swiper>
                            </div>
                            :
                            <></>
                    }
                    {
                        (token && conexiones.length > 0) ?
                            <div className="benefits">
                                <h1 className={'mb-1'}>Tus Conexiones 🤝</h1>
                                <Swiper
                                    slidesPerView={"auto"}
                                    spaceBetween={30}
                                    pagination={{
                                        clickable: true,
                                    }}
                                    className={"swiper"}
                                    navigation={true}
                                    modules={[Pagination]}
                                    loop={true}
                                >
                                    {conexiones.map((item) => {
                                        return (
                                            <SwiperSlide
                                                key={"beneficio_conexion_" + item.id}
                                                className={"swiper-slide-auto"}
                                            >
                                                <BeneficioCard key={'beneficio-' + item.id} beneficio={item}/>
                                            </SwiperSlide>
                                        );
                                    })}
                                </Swiper>
                            </div>
                            :
                            <></>
                    }
                    {
                        categorias.map((categoria) => {
                            return <div key={'contenedor_swiper_' + categoria.nombre+'_'+categoria.id} className="benefits">
                                {<a href={'/categorias/'+categoria.id}><h1>{categoria.nombre} 🍬</h1></a>}
                                {categoria.beneficios.length > 0 ?
                                    <Swiper
                                        key={'swiper_' + categoria.nombre+'_'+categoria.id}
                                        slidesPerView={"auto"}
                                        spaceBetween={30}
                                        pagination={{
                                            clickable: true,
                                        }}
                                        className={"swiper"}
                                        navigation={true}
                                        modules={[Pagination]}
                                        loop={true}
                                    >
                                        {categoria.beneficios?.map((item) => {
                                            return (
                                                <SwiperSlide
                                                    key={"beneficio_categoria_"  + categoria.nombre+'_'+categoria.id + '_' + item.id}
                                                    className={"swiper-slide-auto"}
                                                >
                                                    <BeneficioCard key={'beneficio-' + item.id} beneficio={item}/>
                                                </SwiperSlide>
                                            );
                                        })}
                                    </Swiper>
                                    :
                                    <></>
                                }
                            </div>
                        })
                    }
                    <NavbarBottom/>
                </section>
            </main>
        </>
    );
};

export default Home;
