import React, {PropsWithChildren, useEffect, useState} from "react";
import {ModalMin} from "./ModalMin";

type ModalProps = {
    show?: boolean,
    closeOnEsc?: boolean,
    handleClose: () => void,
    type?: number
};

const Frame: React.FC<PropsWithChildren<ModalProps>> = (
    {
        show = true,
        handleClose,
        closeOnEsc = false,
        type,
        children
    }
) => {
    const showHideClassName = show ? "modal__container" : "modal__container hidden";
    const [showMiniModal, setShowMiniModal] = useState(true);
    useEffect(() => {
        const onKeyPress = (e: KeyboardEvent) => {
            if (closeOnEsc && show && e.key === "Escape") handleClose();
        };
        window.addEventListener("keydown", onKeyPress);
        return () => window.removeEventListener("keydown", onKeyPress);
    }, [closeOnEsc, handleClose, show]);
    return (
        <section className={showHideClassName}>
            {showMiniModal && type !== undefined ?
                <ModalMin
                    handleClose={() => {
                        setShowMiniModal(false)
                    }}
                    type={type}
                /> :
                <div className="modal">
                    <div className="modal__top">
                        {type !== undefined ?
                            <button className="action" onClick={() => setShowMiniModal(true)}>
                                <i className="ico-question-fill">
                                </i>
                            </button>
                            : <button className="action">

                            </button>}
                        <button className="action" onClick={() => handleClose()}>
                            <i className="ico-close"></i>
                        </button>
                    </div>
                    {children}
                </div>
            }
            <div className="backdrop-whited"></div>
        </section>
    );
}

const LetterHead: React.FC<PropsWithChildren> = ({children}) => <div className="modal__letterhead">{children}</div>;
const Content: React.FC<PropsWithChildren> = ({children, ...props}) => <div {...props}
                                                                            className="modal__content">{children}</div>;
const Footer: React.FC<PropsWithChildren> = ({children, ...props}) => <div {...props}
                                                                           className="modal__footer">{children}</div>;

export const ModalPerfil = {Frame, LetterHead, Content, Footer};