import React, {useState} from "react";
import Beneficio from "../types/beneficios.type";
import {base64StringToBlob, createObjectURL} from "blob-util";
import imageKandy from "../assets/images/brand-isotype.png";

interface BeneficioCardProps {
    beneficio: Beneficio
}

const BeneficioCard = (props: BeneficioCardProps) => {
    let imageComercio;
    let imageBeneficio;
    let imageConvenio;
    if (props.beneficio.comercio?.imagen != null && props.beneficio.comercio?.imagen.length > 0) {
        let blob = base64StringToBlob(props.beneficio.comercio?.imagen);
        const blobUrl = createObjectURL(blob);
        imageComercio =
            <span className={'d-flex card__footer_item'}><img className={'img-commerce-footer'} src={blobUrl}
                                                              alt={props.beneficio.comercio.nombre}/></span>
    } else {
        imageComercio = (props.beneficio.comercio?.nombre ?? '')
        imageComercio = imageComercio.length > 16 ? imageComercio.substring(0, 16) + '...' : imageComercio;
        imageComercio = <p className={'d-flex card__footer_item'}><span title={(props.beneficio.comercio?.nombre ?? '')}
                                                                        className={'text-commerce'}>{imageComercio}</span>
        </p>
    }
    if (props.beneficio.imagen != null) {
        imageBeneficio = <img src={props.beneficio.imagen} alt={''}/>
    } else {
        imageBeneficio = <img src={imageKandy} alt={''}/>
    }
    if (props.beneficio.comercio?.convenio?.imagen != null && props.beneficio.comercio?.convenio?.imagen.length > 0) {
        let blob = base64StringToBlob(props.beneficio.comercio?.convenio?.imagen);
        const blobUrl = createObjectURL(blob);
        imageConvenio = <img src={blobUrl} title={props.beneficio.comercio?.convenio?.nombre}
                             alt={props.beneficio.comercio?.convenio?.nombre}/>;
    } else {
        imageConvenio = props.beneficio.comercio?.convenio?.nombre;
    }

    const regex = /(<([^>]+)>)/ig;

    return (
        <a key={'beneficio-card-' + props.beneficio.id} href={"/beneficios/" + props.beneficio.id}
           className="no-decoration">
            {props.beneficio.destacado == 1 ?
                <div className={"card benefit-card card-destacada"}>
                    <div className="card__content__destacada">
                        <div className="card__thumb__destacada">
                        <span className={(props.beneficio.destacado == 1 ? " card__thumb__img " : "")}>
                            {imageBeneficio}
                        </span>
                        </div>
                        <div className="card__info__destacada">
                            <h3 className="title">{props.beneficio.descuento !== "" ? props.beneficio.descuento.replace(regex, '') : 'Aprovecha!'} - {props.beneficio.comercio?.nombre}</h3>
                            <span
                                className="caption">{props.beneficio.descripcion.replace(regex, '').substring(0, 80)}</span>
                            <hr className={'card__foooter__separator'}/>
                            <div className="card__corporation">
                                <div className={'d-flex card__footer_item'}>
                                    <h3>Por:&nbsp;&nbsp;</h3>
                                </div>
                                <span className={'d-flex card__footer_item'}>{imageConvenio}</span>
                                <p className={'d-flex card__footer_item'}>&nbsp;-&nbsp;</p>
                                {imageComercio}
                            </div>
                        </div>
                    </div>
                    {
                        props.beneficio.destacado ==1 ?
                            <span className="card__status color-benefits card__destacados">
                            <i className="ico-star"></i>
                            <span> ¡Beneficio destacado!</span>
                        </span>
                            : ''
                    }
                </div>
                :
                <div className="card benefit-card">
                    <div className="card__content">
                        <div className="card__thumb">
                            {imageBeneficio}
                        </div>
                        <div className="card__info">
                            <h3 className="title">{props.beneficio.descuento !== "" ? props.beneficio.descuento.replace(regex, ''): 'Aprovecha!'} - {props.beneficio.comercio?.nombre}</h3>
                            <span
                                className="caption">{props.beneficio.descripcion.replace(regex, '').substring(0, 80)}</span>
                            <div className="card__corporation">
                                <span>Por: &nbsp;</span>
                                {imageConvenio}
                            </div>
                        </div>
                    </div>
                    {
                        props.beneficio.destacado == 1 ?
                            <span className="card__status color-benefits card__destacados">
                            <i className="ico-star"></i>
                            <span> ¡Beneficio destacado!</span>
                        </span>
                            : ''
                    }
                </div>
            }
          
        </a>
    );
}

export default BeneficioCard;