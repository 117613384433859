import React, {FC, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import AuthService from "../services/auth.service";
import * as Yup from "yup";
import {toast} from "react-toastify";
import Loading from "./Loading";
import {ErrorMessage, Field, Form, Formik} from "formik";
import {useLiveQuery} from "dexie-react-hooks";
import TokenService from "../services/token.service";

const Register: FC = () => {
    const {domi, email} = useParams();
    const [loading, setLoading] = useState<boolean>(false);
    let navigate = useNavigate();
    const currentUser = useLiveQuery(() => TokenService.getUser());

    if (domi !== 'kandy' || currentUser != null) {
        navigate("/");
    }

    const initialValues: {
        name: string;
        last_name: string;
        username: string;
        password: string;
    } = {
        name: "",
        last_name: "",
        username: email ?? '',
        password: "",
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string().required("¡Este campo es requerido!"),
        username: Yup.string().required("¡Este campo es requerido!").email("¡Debe ser un email válido!"),
        password: Yup.string()
            .min(8, '¡Debe tener mínimo 8 caracteres!')
            .matches(
                /^(.*[A-Z].*)$/,
                "¡Debe contener al menos una letra mayúscula!"
            )
            .matches(
                /^(.*\d.*)$/,
                "¡Debe contener al menos un número!"
            )
            .matches(
                /^(.*[,.:;].*)$/,
                "¡Debe contener al menos un signo de puntuación ( , . : ; )!"
            )
            .required("¡Este campo es requerido!"),
    });

    const handleEyeClick = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        const inputPass = (document.getElementById('password') as HTMLInputElement),
            togglePass = (document.getElementById('private') as HTMLElement);
        if (inputPass!.type === 'password') {
            inputPass!.setAttribute('type', 'text')
            togglePass!.classList.add('is-active')
        } else {
            inputPass!.setAttribute('type', 'password')
            togglePass!.classList.remove('is-active')
        }
    };

    const handleRegister = (formValue: {
        name: string;
        last_name: string;
        username: string;
        password: string;
    }) => {
        const {name, last_name, username, password} = formValue;
        setLoading(true);
        AuthService.register(name, last_name, username, password, 'kandy').then(
            () => {
                navigate("/");
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                setLoading(false);
                toast.warn(resMessage, {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        );
    };

    return (
        <>
            <Loading isLoaded={!loading}/>
            <main role="main">
                <section className="login">
                    <h1>¡Ingresa tus datos!</h1>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleRegister}
                    >
                        {({errors, touched}) => (
                            <Form
                                className="form"
                            >
                                <div className="form__field">
                                    <Field name="name" className="input input--shadow" id="name" type="text"
                                           autoComplete="off"
                                           placeholder="* Escribe tu nombre"/>
                                </div>
                                <ErrorMessage
                                    name="name"
                                    component="div"
                                    className="alert alert-danger"
                                />
                                <div className="form__field">
                                    <Field name="last_name" className="input input--shadow" id="last_name" type="text"
                                           autoComplete="off"
                                           placeholder="Escribe tu apellido"/>
                                </div>
                                <ErrorMessage
                                    name="last_name"
                                    component="div"
                                    className="alert alert-danger"
                                />
                                <div className="form__field">
                                    <Field
                                        name="username" className="input input--shadow" id="username" type="text"
                                        autoComplete="off"
                                        placeholder="Escribe tu email"
                                    />
                                    {errors.username && touched.username ? null :
                                        <span className="caption">Se enviará un email para verificar la cuenta</span>
                                    }

                                </div>
                                <ErrorMessage
                                    name="username"
                                    component="div"
                                    className="alert alert-danger"
                                />
                                <div className="form__field is-password">
                                <span className="private" id="private" onClick={handleEyeClick}><i
                                    className="ico-eye"></i>
                                </span>
                                    <Field name="password" className="input input--shadow" id="password"
                                           type="password"
                                           autoComplete="off"
                                           placeholder="* Escribe tu contraseña"/>
                                </div>
                                <ErrorMessage
                                    name="password"
                                    component="div"
                                    className="alert alert-danger"
                                />
                                <div>
                                    La contraseña debe tener:
                                    <ul className="text-start list-style-closed">
                                        <li>Mínimo 8 caracteres</li>
                                        <li>Al menos una letra mayúscula</li>
                                        <li>Al menos un número</li>
                                        <li>Al menos un signo de puntuación <br/><strong>( , . : ; )</strong></li>
                                    </ul>
                                </div>
                                <div className="form__field button-container">
                                    <button className="btn" type="submit" disabled={loading}>Registrar</button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                    
                    <a href="/">Volver</a>
                </section>
            </main>
        </>
    );
}

export default Register;