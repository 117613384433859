import React, {useEffect, useState} from 'react';

import {useNavigate, useParams} from "react-router-dom";
import Loading from "./Loading";
import "swiper/css";
import {getBeneficios, getComercios, getConvenios} from "../services/empresas.service";
import {responseToast} from "../common/helpers";
import TokenService from "../services/token.service";
import Beneficios from "../types/beneficios.type";
import {base64StringToBlob, createObjectURL} from "blob-util";
import {HandshakeOutlined, StoreOutlined} from "@mui/icons-material";
import Navbar from "./Navbar";
import ConvenioEmpresa from "../types/convenios-empresas.type";

const Convenio: React.FC = () => {
    const [loaded, setLoaded] = useState<boolean>(false);
    const [beneficios, setBeneficios] = useState<Beneficios[]>([]);
    const [convenioEmpresa, setConvenioEmpresa] = useState<ConvenioEmpresa>();
    const navigate = useNavigate();
    const {id} = useParams();
    const [idComercio, setIdComercio] = useState(id);

    const handleBack = () => {
        navigate(-1);
    };

    const handleShare = () => {
        try {
            navigator.share({
                title: 'Kandy.cl',
                text: 'Mira este comercio de la APP Kandy'
            });
        } catch (error) {
            console.error(error)
        }
    };

    let imageComercio;
    if (convenioEmpresa?.convenio?.imagen != null && convenioEmpresa?.convenio?.imagen.length > 0) {
        let blob = base64StringToBlob(convenioEmpresa?.convenio?.imagen);
        const blobUrl = createObjectURL(blob);
        imageComercio = <img src={blobUrl} alt={convenioEmpresa?.convenio?.nombre}/>
    } else {
        imageComercio = <HandshakeOutlined fontSize='inherit'/>;
    }

    useEffect(() => {
        if (id === null || id === undefined) {
            navigate('/');
        }
        setLoaded(false);
        const getBeneficiosPorConvenio = getBeneficios(null, null,null, parseInt(id ?? '0')).then((data) => {
                return data;
            },
            (error) => {
                responseToast(error);
                return [];
            });
        const getInfoConvenio = getConvenios( parseInt(id ?? '0')).then((data) => {
                return data;
            },
            (error) => {
                responseToast(error);
                return [];
            });

        Promise.all([getBeneficiosPorConvenio, getInfoConvenio]).then(data => {
            setBeneficios(data[0]);
            setConvenioEmpresa(data[1]);
            setLoaded(true);
        })
    }, []);
    return (
        <>
            <Loading isLoaded={loaded}/>
            <Navbar/>
            <main role="main" className={'ai-fs'}>
                <section className="page page--benefits">
                    <div className="letterhead letterhead--column">
                        <div className="letterhead__actions">
                            <button className="action" onClick={handleBack}>
                                <i className="ico-back-circle"></i>
                            </button>
                            <button className="action" onClick={handleShare}>
                                <i className="ico-upload"></i>
                            </button>
                        </div>
                        <div className="launch">
                            <div className="launch" style={
                                {fontSize: 64}
                            }>
                                {imageComercio}
                            </div>
                            <p className="lead">Aprovecha estos beneficios que <b>{convenioEmpresa?.convenio?.nombre}</b> tine para tí</p>
                        </div>
                    </div>
                    <div className="block block--round">
                        <div className="lead text-center mb-1">Tenemos {beneficios.length ?? 0} beneficios para ti</div>
                        <div className="benefits mb-1">
                            {beneficios.map((item) => {
                                let imageComercio;
                                if (item.comercio?.imagen != null && item.comercio?.imagen.length > 0) {
                                    let blob = base64StringToBlob(item.comercio?.imagen);
                                    const blobUrl = createObjectURL(blob);
                                    imageComercio = <img src={blobUrl} alt={item.comercio.nombre}/>
                                } else {
                                    imageComercio = <StoreOutlined fontSize='inherit'/>;
                                }
                                let imageConvenio;
                                if (item.comercio?.convenio?.imagen != null && item.comercio?.convenio?.imagen.length > 0) {
                                    let blob = base64StringToBlob(item.comercio?.convenio?.imagen);
                                    const blobUrl = createObjectURL(blob);
                                    imageConvenio = <img src={blobUrl} alt={item.comercio?.convenio?.nombre}/>
                                } else {
                                    imageConvenio = item.comercio?.convenio?.nombre;
                                }
                                return (
                                    <a key={'beneficio_'+ item.id} href={"/beneficios/" + item.id} className="no-decoration">
                                        <div className="card benefit-card">
                                            <div className="card__content">
                                                <div className="card__thumb">
                                                    {imageComercio}
                                                </div>
                                                <div className="card__info">
                                                    <h3 className="title">{item.descuento !== "" ? item.descuento : '\u00A0'}</h3>
                                                    <span
                                                        className="caption">{item.descripcion.substring(0, 50)}{(item.descripcion.length > 50 ? '...' : '')}</span>
                                                    <div className="card__corporation">
                                                        <span>Por: &nbsp;</span>
                                                        {imageConvenio}
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                item.destacado == 1 ?
                                                    <span className="card__status color-benefits">
                                                    <i className="ico-star"></i>
                                                    <span> ¡Beneficio destacado!</span>
                                                </span>
                                                    : ''
                                            }
                                        </div>
                                    </a>
                                );
                            })}
                        </div>
                    </div>
                </section>
            </main>
        </>
    );
}
export default Convenio;