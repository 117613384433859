import React, {PropsWithChildren, useEffect} from "react";
import thumbConexionesClick from '../assets/images/miniatura-conexiones-click.svg';
import thumbConexionesToque from '../assets/images/miniatura-conexiones-toque.svg';

type MiniModalProps = {
    show?: boolean,
    closeOnEsc?: boolean,
    handleClose: () => void,
    type?: number
};

export const ModalMin: React.FC<PropsWithChildren<MiniModalProps>> = (
    {
        show = true,
        handleClose,
        closeOnEsc = false,
        type = 0,
        children
    }
) => {
    useEffect(() => {
        const onKeyPress = (e: KeyboardEvent) => {
            if (closeOnEsc && show && e.key === "Escape") handleClose();
        };
        window.addEventListener("keydown", onKeyPress);
        return () => window.removeEventListener("keydown", onKeyPress);
    }, [closeOnEsc, handleClose, show]);

    return (
        <section className="page">
            <div className="dialog dialog--clear dialog--small">
                <div className="dialog__head mb-0">
                    <div className="dialog__actions pb-0">
                        <button className="action align-end" onClick={() => handleClose()}>
                            <i className="ico-close"></i>
                        </button>
                    </div>
                </div>
                <div className="dialog__contet">
                    <div className="message">
                        <picture>
                            <source media="(min-width: 768px)" srcSet={thumbConexionesClick}/>
                            <img src={thumbConexionesToque} alt=""/>
                        </picture>
                        {
                            type === 0 ?
                                <>
                                    <h1 className="subtitle mb-0">Selecciona los servicios o convenios que tengas de
                                        esta
                                        lista.</h1>
                                    <p className="lead mb-0">Dinos a que estas afiliado y podrás conocer tus
                                        beneficios</p>
                                </>
                                :
                                <>
                                    <h1 className="subtitle mb-0">Selecciona tus gustos para ver los mejores beneficios
                                        para ti.</h1>
                                    <p className="lead mb-0">Dinos a que estas afiliado y podrás conocer tus
                                        beneficios</p>
                                </>
                        }
                        <div className="button-container">

                            <button className="btn btn--default" onClick={() => handleClose()}>Continuar</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="backdrop"></div>
        </section>
    );
}