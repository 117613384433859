import DefaultLogo from "../assets/images/brand-horizontal.png";
import {db} from '../data/db';

class TokenService {
    async getLocalRefreshToken() {
        const user = await this.getUser();
        return user?.data.refreshToken;
    }

    async getLocalAccessToken() {
        const user = await this.getUser();
        return user?.jwt;
    }

    async updateLocalAccessToken(token: any) {
        const user = await this.getUser();
        db.user.update(user?.id, {jwt: token})
    }

    async updateUserData(data: any) {
        const user = await this.getUser();
        db.user.update(user?.id, {data: data})
    }

    async getUser() {
        return await db.user.orderBy('id').last();
    }

    setUser(user: any) {
        db.user.add(user)
    }

    async removeUser() {
        const user = await this.getUser();
        db.user.delete(user?.id);
    }

    async getLogo() {
        const user = await this.getUser();
        return user?.data.empresas_id == 308 ? DefaultLogo : (user?.data.empresas_logo ?? DefaultLogo);
    }
}

export default new TokenService();