import React, { useState, useEffect } from "react";

import "../assets/styles/scrollToTop.css";
import {ArrowUpwardRounded} from "@mui/icons-material";
import {Tooltip} from "@mui/material";

const ScrollToTop = () => {
    const [showTopBtn, setShowTopBtn] = useState(false);
    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.scrollY > 600) {
                setShowTopBtn(true);
            } else {
                setShowTopBtn(false);
            }
        });
    }, []);
    const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };
    return (
        <div className="top-to-btm">
            {" "}
            {showTopBtn && (
                <Tooltip title={'Ir arriba'} arrow>
                    <ArrowUpwardRounded
                        fontSize={"inherit"}
                        className="icon-position icon-style"
                        onClick={goToTop}
                    />
                </Tooltip>
            )}{" "}
        </div>
    );
};
export default ScrollToTop;