import React, {FC, useState} from "react";
import FacebookLogin from "@greatsumini/react-facebook-login";
import {toast} from "react-toastify";
import {useGoogleLogin} from "@react-oauth/google";
import AuthService from "../services/auth.service";
import {useNavigate} from "react-router-dom";
import googleLogo from '../assets/images/autentificacion-google.svg';
import facebookLogo from '../assets/images/autentificacion-facebook.svg';

const LoginKandy: FC = () => {
    const [loading, setLoading] = useState<boolean>(false);
    let navigate = useNavigate();
    
    const handleGoogleLogin = useGoogleLogin({
        onSuccess: tokenResponse => {
            setLoading(true);
            AuthService.loginGoogle(tokenResponse).then(() => {
                setLoading(false);
                navigate("/");
            });
        },
        onError: () => setLoading(false),
    });

    const handleFacebookLogin = (response: any) => {
        setLoading(true);
        AuthService.loginFacebook(response).then(() => {
            setLoading(false);
            navigate("/");
        }).catch(() => console.error('errorr facebook'));
    }

    return (<>
            <h1 className="title-h1">
                Inicia sesión o registrate para acceder a todos tus
                beneficios.</h1>
            <ul className="auth-services">
                <li className="auth-services__item">
                    <button className="btn btn--light btn--icon btn--shadow w100" onClick={() => handleGoogleLogin()}>
                        <img src={googleLogo} alt="autentificación google"/>
                        <b>Continuar con Google</b>
                    </button>
                </li>
                <li className="auth-services__item">
                    <FacebookLogin
                        appId="774248337134373"
                        onFail={(error) => {
                            toast.warn('Hubo un problema para ingresar, reintente');
                        }}
                        onProfileSuccess={handleFacebookLogin}
                        render={({onClick}) => (
                            <button className="btn btn--light btn--icon btn--shadow w100" onClick={onClick}>
                                <img src={facebookLogo} alt="autentificación Facebook"/>
                                <b>Continuar con Facebook</b>
                            </button>
                        )}
                    />

                </li>
            </ul>
            <span className="caption">O continua con tu correo electrónico</span>
        </>
    );
}

export default LoginKandy;

