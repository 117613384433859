import React, {PropsWithChildren, useState} from "react";
import {KeyboardArrowDown, KeyboardArrowUp} from "@mui/icons-material";
import "../assets/styles/readMore.css";

const ReadMore: React.FC<PropsWithChildren> = ({children}) => {
    const text = children?.toString();

    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    };
    return (
        <>
            <p className={"text-readmore " + (!isReadMore ? 'reading-more' : '')}>
                {children}
            </p>
            <span onClick={toggleReadMore} className="pointer bold">
                {(typeof text != 'undefined' && text.length > 400) ?
                    isReadMore ?
                        <>
                            <KeyboardArrowDown fontSize={"inherit"}/> Ver más
                        </> :
                        <>
                            <KeyboardArrowUp fontSize={"inherit"}/> Ver menos
                        </>
                    : ''
                }
      </span>
        </>
    );
};


export default ReadMore;