import React, {useEffect, useState} from 'react';
import {Navigate, Route, Routes, useLocation} from "react-router-dom";
import {toast, ToastContainer} from "react-toastify";
import withLoadingScreen from "react-loading-screen-hoc";

import Login from "./components/Login";
import Home from "./components/Home";
import NotFound from "./components/NotFound";
import ForgotPassword from "./components/ForgotPassword";
import RecoverPassword from "./components/RecoverPassword";
import Loading from "./components/Loading";
import Profile from "./components/Profile";
import Beneficio from "./components/Beneficio";
import Categoria from "./components/Categoria";
import Comercio from "./components/Comercio";
import ListaComercios from "./components/ListaComercios";
import Buscador from "./components/Buscador";
import ScrollToTop from "./components/ScrollToTop";
import Notification from './components/Notification';
import Convenio from "./components/Convenio";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import {Button} from "@mui/material";
import Register from "./components/Register";
import PrivateWrapper from "./components/PrivateRouter";
import ListaBeneficios from "./components/ListaBeneficios";

const App: React.FC = () => {
    const [showReload, setShowReload] = useState(false);
    const [waitingWorker, setWaitingWorker] = useState<ServiceWorker | null>(null);
    const [registrationSw, setRegistrationSw] = useState<ServiceWorkerRegistration | null>(null);

    const onSWUpdate = (registration: ServiceWorkerRegistration) => {
        setShowReload(true);
        setWaitingWorker(registration.waiting);
        setRegistrationSw(registration);
    };

    const reloadPage = () => {
        registrationSw?.unregister();
        waitingWorker?.postMessage({type: 'SKIP_WAITING'});
        setShowReload(false);
        toast.dismiss();
        window.location.href = "/";
    };

    useEffect(() => {
        serviceWorkerRegistration.register({onUpdate: onSWUpdate});
    }, []);

    useEffect(() => {
        if (showReload) {
            toast.info(
                <div className={'text-center'}>
                    ¡Nueva versión disponible! <Button onClick={reloadPage} color={"inherit"}
                                                       variant={"text"}>Recargar</Button>
                </div>, {
                    position: "bottom-center",
                    autoClose: false,
                    hideProgressBar: true,
                    closeOnClick: false,
                    closeButton: false,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                });
        }
    }, [showReload]);
    let location = useLocation();

    useEffect(() => {
        navigator.serviceWorker
            .getRegistrations()
            .then((regs) => regs.forEach((reg) => reg.update()));
    }, [location]);


    return (
        <>
            <ScrollToTop/>
            <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path="/home" element={
                    <Navigate replace to="/"/>
                }/>
                <Route path="/:domi" element={<Login/>}/>
                <Route path="/login/:domi" element={<Login/>}/>
                <Route path="/forgot-password/" element={<ForgotPassword/>}/>
                <Route path="/forgot-password/:domi" element={<ForgotPassword/>}/>
                <Route path="/recover-password/:domi/:token" element={<RecoverPassword/>}/>
                <Route path="/register/:domi" element={<Register/>}/>
                <Route path="/register/:domi/:email" element={<Register/>}/>
                <Route path="/perfil" element={
                    <PrivateWrapper>
                        <Profile/>
                    </PrivateWrapper>
                }/>
                <Route path="/comercios" element={<ListaComercios/>}/>
                <Route path="/comercios/:id" element={<Comercio/>}/>
                <Route path="/categorias" element={<Home/>}/>
                <Route path="/categorias/:id" element={<Categoria/>}/>
                <Route path="/convenios/" element={<Home/>}/>
                <Route path="/convenios/:id" element={<Convenio/>}/>
                <Route path="/beneficios/" element={<ListaBeneficios/>}/>
                <Route path="/beneficios/:beneficioId" element={<Beneficio/>}/>
                <Route path="/buscador" element={<Buscador/>}/>
                <Route path="*" element={<NotFound/>}/>
            </Routes>
            <Notification/>
            <ToastContainer
                position="bottom-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </>
    );
}

export default withLoadingScreen(App, Loading);
