import api from "./api";
import TokenService from "./token.service";

export const updateProfile = (nombre: string, apellido: string, fecha_nacimiento: string, foto_perfil: string, telefono_particular: string) => {
    const currentUser = TokenService.getUser();
    const profileData = new FormData();
    currentUser.then((resolve) => {
        const userData = resolve?.data;
        profileData.append("id", userData?.id);
    })
    profileData.append("nombre", nombre);
    profileData.append("apellido", apellido);
    profileData.append("fecha_nacimiento", fecha_nacimiento);
    profileData.append("foto_perfil", foto_perfil);
    profileData.append("telefono_particular", telefono_particular);

    return api
        .post("?controller=personas&action=updateProfile", profileData, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        })
        .then((response) => {
            TokenService.updateUserData(response.data.data)
        });
}

export const getConvenios = () => {
    return api
        .get("?controller=personas&action=getConvenios")
        .then((response) => {
            return response.data;
        });
}

export const getCategorias = () => {
    return api
        .get("?controller=personas&action=getCategorias")
        .then((response) => {
            return response.data;
        });
}

export const getBeneficio = (beneficio: string) => {
    const data = new FormData();
    data.append("beneficio", beneficio);
    return api
        .post("?controller=personas&action=getBeneficio", data)
        .then((response) => {
            return response.data;
        });
}

export const updadateCategorias = (categorias: any[]) => {
    const categoriasData = new FormData();
    for (let value of categorias) {
        categoriasData.append("categorias[]", value);
    }
    return api
        .post("?controller=personas&action=updateCategorias", categoriasData);
}

export const updadateConvenios = (convenios: any[]) => {
    const conveniosData = new FormData();
    for (let value of convenios) {
        conveniosData.append("convenios[]", value);
    }
    return api
        .post("?controller=personas&action=updateConvenios", conveniosData);
}

export const saveTokenNotification = (token: string) => {
    const data = new FormData();
    data.append("token", token);
    return api
        .post("?controller=personas&action=saveTokenNotification", data);
}