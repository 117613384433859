import axios from "axios";
import TokenService from "./token.service";
import AuthService from "./auth.service";
import config from "./conf";

const instance = axios.create({
    baseURL: config.api_url,
    withCredentials: true,
    headers: {
        "Content-Type": "application/json",
    }
});

instance.interceptors.request.use(
    async (config) => {
        const token = await TokenService.getLocalAccessToken();
        const userData = await TokenService.getUser();

        if (token) {
            if (config.headers === undefined) {
                config.headers = {};
            }
            config.headers.Authorization = `Bearer ${token}`;
            if (config.data instanceof FormData) {
                config.data.append('eid', userData?.data.empresas_id);
                config.data.append('id', userData?.data.id);
            } else {
                if (typeof config.params == 'undefined') {
                    config.params = {};
                }
                config.params.id = userData?.data.id;
                config.params.eid = userData?.data.empresas_id;
            }
        } else {
            if (config.data instanceof FormData) {
                config.data.append('eid', '308');
            } else {
                if (typeof config.params == 'undefined') {
                    config.params = {};
                }
                config.params.eid = 308;
            }
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);
instance.interceptors.response.use(
    (res) => {
        return res;
    },
    async (error) => {
        const originalConfig = error.config;
        if (error.config != undefined) {
            if (error.config?.url === '?controller=personas&action=refreshToken') {
                originalConfig._retry = false;
                AuthService.logout();
                window.location.reload();
            } else {
                if (![
                        "?controller=personas&action=login",
                        "?controller=personas&action=registerEmail",
                        "?controller=personas&action=forgotPassword",
                        "?controller=personas&action=validateToken",
                        "?controller=personas&action=changePassword"
                    ].includes(error.config.url)
                    && error.response) {
                    if (error.response.status === 401 && !originalConfig._retry) {
                        originalConfig._retry = true;
                        try {
                            const rs = await instance.post("?controller=personas&action=refreshToken");
                            const {jwt} = rs.data;
                            await TokenService.updateLocalAccessToken(jwt);
                            return instance(originalConfig);
                        } catch (_error) {
                            return Promise.reject(_error);
                        }
                    } else {
                        if (error.response.status === 403 && !originalConfig._retry) {
                            originalConfig._retry = false;
                            AuthService.logout();
                            window.location.reload();
                        }
                    }
                }
            }
        }
        return Promise.reject(error);
    }
);

export default instance;