import api from "./api";

export const getCategorias = (categoria?: number | null) => {
    return api
        .get("?controller=empresas&action=getCategorias", {params: {categoria}})
        .then((response) => {
            return response.data;
        });
}

export const getConvenios = (convenio?: number | null) => {
    return api
        .get("?controller=empresas&action=getConvenios", {params: {convenio}})
        .then((response) => {
            return response.data;
        });
}

export const getComercios = (categoria?: number | null, comercio?: number | null) => {
    return api
        .get("?controller=empresas&action=getComercios", {params: {categoria, comercio}})
        .then((response) => {
            return response.data;
        });
}

export const getBeneficios = (beneficio?: number | null, destacados?: boolean | null, comercio?: number | null, convenio?: number | null | boolean, categoria?: number | null, limit?: number | null) => {
    return api
        .get("?controller=empresas&action=getBeneficios", {
            params: {
                beneficio,
                destacados,
                comercio,
                convenio,
                categoria,
                limit
            }
        })
        .then((response) => {
            return response.data;
        });
}

export const getBeneficiosHome = () => {
    return api
        .get("?controller=empresas&action=getBeneficiosHome")
        .then((response) => {
            return response.data;
        });
}

export const getBeneficiosDelDia = () => {
    return api
        .get("?controller=empresas&action=getBeneficiosDelDia")
        .then((response) => {
            return response.data;
        });
}

export const getTags = () => {
    return api
        .get("?controller=empresas&action=getTags")
        .then((response) => {
            return response.data;
        });
}

export const buscarBeneficios = (source: any, busqueda?: string | null, tags?: any[] | null) => {
    return api
        .get("?controller=empresas&action=getBeneficiosBusqueda", {
            params: {busqueda, tags},
            cancelToken: source.token
        });
}