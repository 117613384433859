import React, {useEffect, useState} from 'react';

import {useNavigate} from "react-router-dom";
import Loading from "./Loading";
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css";
import {getCategorias, getComercios} from "../services/empresas.service";
import {responseToast} from "../common/helpers";
import TokenService from "../services/token.service";
import Categorias from "../types/categorias.type";
import {base64StringToBlob, createObjectURL} from "blob-util";
import {StoreOutlined} from "@mui/icons-material";
import Navbar from "./Navbar";
import Comercios from "../types/comercios.type";

const ListaComercios: React.FC = () => {
    const [loaded, setLoaded] = useState<boolean>(false);
    const [listaComercios, setListaComercio] = useState<Comercios[]>([]);
    const navigate = useNavigate();
    const [listaCategorias, setListaCategoria] = useState<Categorias[]>([]);
    const [idCategoria, setIdCategoria] = useState();

    const handleBack = () => {
        navigate(-1);
    };
    
    useEffect(() => {
        setLoaded(false);
        const getListaComercios = getComercios( idCategoria).then((data) => {
                return data;
            },
            (error) => {
                responseToast(error);
                return [];
            });
        const getAllCategoria = getCategorias().then((data) => {
                return data;
            },
            (error) => {
                responseToast(error);
                return [];
            });

        Promise.all([getListaComercios, getAllCategoria]).then(data => {
            setListaComercio(data[0]);
            setListaCategoria(data[1]);
            setLoaded(true);
        })
    }, [idCategoria]);
    return (
        <>
            <Loading isLoaded={loaded}/>
            <Navbar/>
            <main role="main" className={'ai-fs'}>
                <section className="page page--commerce">
                    <div className="letterhead letterhead--default letterhead--column">
                        <div className="letterhead__actions">
                            <button className="action" onClick={handleBack}>
                                <i className="ico-back-circle"></i>
                            </button>
                        </div>
                        <div className="launch">
                            <i className="ico-commerce"></i>
                            <h1 className="title">Red de Comercios</h1>
                            <h2 className="headline">
                                Aquí encontrarás los comercios en donde puedes usar tus
                                beneficios.
                            </h2>
                        </div>
                    </div>
                    <div className="block block--round">
                        <div className="filters">
                            <Swiper
                                slidesPerView={'auto'}
                                spaceBetween={16}
                            >
                                <SwiperSlide
                                    role={'group'}
                                >
                                    <button className="tag disabled no-decoration">
                                        <i className="ico-filters"></i>
                                        <span>Filtros</span>
                                    </button>
                                </SwiperSlide>
                                <SwiperSlide
                                    role={'group'}
                                >
                                    <button
                                        onClick={() => {
                                            setIdCategoria(undefined)
                                        }}
                                        className={"no-decoration pointer tag " + (idCategoria === null || idCategoria === undefined ? 'is-active' : '')}>
                                        <span>Todo</span>
                                    </button>
                                </SwiperSlide>
                                {
                                    listaCategorias?.map((item) => {
                                        return (
                                            <SwiperSlide
                                                key={"categoria_" + item.nombre}
                                            >
                                                <button
                                                    className={'no-decoration  pointer tag ' + (item.id === idCategoria ? 'is-active' : '')}
                                                    onClick={() => {
                                                        setIdCategoria(item.id)
                                                    }}>
                                                    <span>{item.nombre}</span>
                                                </button>
                                            </SwiperSlide>
                                        );
                                    })
                                }
                            </Swiper>
                        </div>

                        {
                            listaComercios!.length > 0 ?
                                <div className="stores">
                                    {
                                        listaComercios?.map((item) => {
                                                let imageComercio;
                                                if (item?.imagen != null && item?.imagen.length > 0) {
                                                    let blob = base64StringToBlob(item?.imagen);
                                                    const blobUrl = createObjectURL(blob);
                                                    imageComercio = <img src={blobUrl} alt={item.nombre}/>
                                                } else {
                                                    imageComercio = <><StoreOutlined
                                                        fontSize='inherit'/> {item.nombre}</>;
                                                }
                                                return (
                                                    <a
                                                        key={"comercios_" + item.nombre + "_" + item.id}
                                                        href={"/comercios/" + item.id}
                                                        className={'store no-decoration'}
                                                        style={{fontSize: 24}}
                                                    >
                                                        {imageComercio}
                                                    </a>
                                                );
                                            }
                                        )
                                    }
                                </div>
                                :
                                <div className={'d-flex justify-contenet-center '}>
                                    <span>No hay comercios a mostrar</span>

                                </div>
                        }
                    </div>
                </section>
            </main>
        </>
    );
}
export default ListaComercios;
