import {useGoogleReCaptcha} from 'react-google-recaptcha-v3';
import React, {useCallback, useEffect, useState} from "react";
import {Field, useFormikContext} from "formik";

const YourReCaptchaComponent = () => {
    const {values, setFieldValue} = useFormikContext<{ token: string }>();
    const { submitForm } = useFormikContext();
    const {executeRecaptcha} = useGoogleReCaptcha();
    const [loading, setLoading] = useState<boolean>(false);

    // Create an event handler so you can call the verification on button click event or form submit
    const handleReCaptchaVerify = useCallback(async () => {
        if (!executeRecaptcha) {
            console.log('Execute recaptcha not yet available');
            return;
        }
        const token = await executeRecaptcha('forgotPassword');
        setFieldValue('token', token)
        // Do whatever you want with the token
    }, [executeRecaptcha]);
    
    const handleClick = () => {
        setLoading(true);
        handleReCaptchaVerify().then(() => {
            submitForm().then(()=>{
                setLoading(false);
            });
        })
    }
    // You can use useEffect to trigger the verification as soon as the component being loaded
    useEffect(() => {
        handleReCaptchaVerify();
    }, [handleReCaptchaVerify]);
    return (
        <div className="form__field button-container">
            <Field
                name="token"
                hidden={true}
                value={values.token}
            />
            <button className="btn" type="button" onClick={handleClick} disabled={loading}>Recuperar</button>
        </div>
    );
};

export default YourReCaptchaComponent;