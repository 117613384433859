import React, {ChangeEvent, ChangeEventHandler, FC, FormEvent, useEffect, useState} from "react";
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from "yup";

import AuthService from "../services/auth.service";
import {useNavigate, useParams} from 'react-router-dom';
import {toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Loading from "./Loading";
import LoginKandy from "./LoginKandy";
import {Cookies} from "react-cookie";
import {useLiveQuery} from "dexie-react-hooks";
import TokenService from "../services/token.service";


const Login: FC = () => {
    let navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const [email, setEmail] = useState<string>('');
    const currentUser = useLiveQuery(() => TokenService.getUser());
    const {domi} = useParams();
    const dominio = domi != 'login' ? (domi ?? 'kandy') : 'kandy';

    const initialValues: {
        username: string;
        password: string;
    } = {
        username: currentUser ? currentUser.data.username : "",
        password: "",
    };

    const validationSchema = Yup.object().shape({
        username: Yup.string().required("¡Este campo es requerido!"),
        password: Yup.string().required("¡Este campo es requerido!"),
    });
    const validationSchemaData = Yup.object().shape({
        password: Yup.string().required("¡Este campo es requerido!"),
    });

    const handleEyeClick = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        const inputPass = (document.getElementById('password') as HTMLInputElement),
            togglePass = (document.getElementById('private') as HTMLElement);
        if (inputPass!.type === 'password') {
            inputPass!.setAttribute('type', 'text')
            togglePass!.classList.add('is-active')
        } else {
            inputPass!.setAttribute('type', 'password')
            togglePass!.classList.remove('is-active')
        }
    };

    const handleLogin = (formValue: { username: string; password: string }) => {
        const {username, password} = formValue;
        setLoading(true);
        AuthService.login(username, password, dominio ?? 'kandy').then(
            () => {
                navigate("/");
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                setLoading(false);
                toast.warn(resMessage, {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        );
    };

    function getPWADisplayMode(): string {
        const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
        // @ts-ignore
        if (navigator.standalone || isStandalone) {
            return 'standalone';
        }
        return 'browser';
    }

    if (getPWADisplayMode() === 'standalone' && currentUser) {
        navigate("/");
    }

    return (
        <>
            <Loading isLoaded={!loading}/>
            <main role="main">
                <section className="login">
                    {currentUser && dominio !== 'kandy' ?
                        <>
                            <h1 className="title">¡Bienvenido<br/>nuevamente!</h1>
                            <div className="profile">
                                <div className="profile__avatar">
                                    <img src={currentUser.data.foto_perfil ?? "/assets/images/profile-dummy.jpeg"}
                                         alt="avatar"/>
                                </div>
                                <strong className="profile__name">{currentUser.data.nombre}</strong>
                                <span className="lead">{currentUser.data.email}</span>
                            </div>
                            <Formik
                                initialValues={initialValues}
                                validationSchema={validationSchemaData}
                                onSubmit={handleLogin}
                            >
                                <Form
                                    className="form"
                                >
                                    <div className="form__field is-password">
                                <span className="private" id="private" onClick={handleEyeClick}><i
                                    className="ico-eye"></i>
                                </span>
                                        <Field name="password"
                                               className="input input--shadow"
                                               id="password"
                                               type="password"
                                               autoComplete="off"
                                               placeholder="Escribe tu contraseña"/>
                                    </div>
                                    <ErrorMessage
                                        name="password"
                                        component="div"
                                        className="alert alert-danger"
                                    />
                                    <div className="form__field button-container">
                                        <button className="btn" type="submit" disabled={loading}>Ingresar</button>
                                    </div>
                                </Form>
                            </Formik>
                        </>
                        : (dominio === 'kandy' ?
                            <LoginKandy/>
                            :
                            <>
                                <h1 className="title">¡Es un gusto<br/>tenerte por aquí!</h1>
                                <div className="profile">
                                    <div className="profile__avatar mb-0">
                                        <img src="/assets/images/profile-thumb.svg" alt="avatar"/>
                                    </div>
                                </div>
                            </>)
                    }
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleLogin}
                    >
                        <Form
                            className="form"
                        >
                            <div className="form__field">
                                <Field name="username" className="input input--shadow" id="username"
                                       onInput={(e: FormEvent<HTMLFormElement>) => {
                                           setEmail(e.currentTarget.value)
                                       }}
                                       type="text"
                                       autoComplete="off"
                                       placeholder={dominio === 'kandy' ? "Escribe tu email" : "Escribe tu nombre de usuario"}/>
                            </div>
                            <ErrorMessage
                                name="username"
                                component="div"
                                className="alert alert-danger"
                            />
                            <div className="form__field is-password">
                                <span className="private" id="private" onClick={handleEyeClick}><i
                                    className="ico-eye"></i>
                                </span>
                                <Field name="password" className="input input--shadow" id="password"
                                       type="password"
                                       autoComplete="off"
                                       placeholder="Escribe tu contraseña"/>
                            </div>
                            <ErrorMessage
                                name="password"
                                component="div"
                                className="alert alert-danger"
                            />
                            <div className="form__field button-container">
                                <button className="btn" type="submit" disabled={loading}>Ingresar</button>
                            </div>
                        </Form>
                    </Formik>
                    <div className={'d-flex direction-column'}>
                        <a href="/forgot-password">Recuperar Contraseña</a>
                        <span>o</span>
                        <a href={`/register/kandy/` + email}>Regístrate</a>
                    </div>
                </section>
            </main>
        </>
    )
        ;
}

export default Login;
