import React, {useEffect, useState} from 'react';

import {useNavigate, useParams} from "react-router-dom";
import Loading from "./Loading";
import {getBeneficios} from "../services/empresas.service";
import {getBeneficio} from "../services/personas.service";
import {responseToast} from "../common/helpers";
import Beneficios from "../types/beneficios.type";
import {base64StringToBlob, createObjectURL} from "blob-util";
import {StoreOutlined} from "@mui/icons-material";
import ReadMore from "./ReadMore";

const Beneficio: React.FC = () => {
    const [loaded, setLoaded] = useState<boolean>(false);
    const [beneficio, setBeneficio] = useState<Beneficios>();
    const navigate = useNavigate();
    const {beneficioId} = useParams();

    const handleClose = () => {
        navigate(-1);
    };

    const handleGiveItToMe = () => {
        getBeneficio(beneficio?.id ?? '');
        window.open(beneficio?.url ?? '/', '_blank', 'noopener,noreferrer');
    };

    let imageComercio;
    if (beneficio?.comercio?.imagen != null && beneficio?.comercio?.imagen.length > 0) {
        let blob = base64StringToBlob(beneficio?.comercio?.imagen);
        const blobUrl = createObjectURL(blob);
        imageComercio = <img src={blobUrl} alt={beneficio?.comercio.nombre}/>
    } else {
        if (beneficio?.imagen != null) {
            imageComercio = <img src={beneficio?.imagen} alt={''}/>
        }else{
            imageComercio = <StoreOutlined fontSize='inherit'/>;
        }
    }

    let imageConvenio;
    if (beneficio?.comercio?.convenio?.imagen != null && beneficio?.comercio?.convenio?.imagen.length > 0) {
        let blob = base64StringToBlob(beneficio?.comercio?.convenio?.imagen);
        const blobUrl = createObjectURL(blob);
        imageConvenio = <img src={blobUrl} alt={beneficio?.comercio?.convenio?.nombre}/>
    } else {
        imageConvenio = beneficio?.comercio?.convenio?.nombre;
    }

    useEffect(() => {
        setLoaded(false);
        const getBeneficio = getBeneficios(parseInt(beneficioId ?? '0')).then((data) => {
                return data;
            },
            (error) => {
                responseToast(error);
                return [];
            });

        Promise.all([getBeneficio]).then(data => {
            if (data[0].length === 0) {
                navigate('/')
            }
            setBeneficio(data[0]);
            setLoaded(true);
        })
    }, [beneficioId]);
    return (
        <main role="main" className="vh100">
            <Loading isLoaded={loaded}/>
            <div className="dialog">
                <div className="dialog__head">
                    <div className="dialog__actions">
                        <a className="action" href={"/categorias/" + (beneficio?.categoria?.id ?? '')}>
                            <i className="ico-store"></i>
                        </a>
                        <strong className="dialog__title">{beneficio?.categoria?.nombre}</strong>
                        <button className="action" onClick={handleClose}>
                            <i className="ico-close"> </i>
                        </button>
                    </div>
                    <img className="dialog__cover" src={beneficio?.imagen} alt=""/>
                </div>
                <div className="dialog__contet">
                    <a className="store" href={"/comercios/" + beneficio?.comercio?.id}>
                        {imageComercio}
                    </a>
                    <div className="dialog__info">
                        <h3 className="lead">{beneficio?.comercio?.nombre}</h3>
                        {beneficio?.destacado === 1 ?
                            <div className="dialog__status color-benefits">
                                <i className="ico-star"></i>
                                <b>¡Beneficio Destacado!</b>
                            </div>
                            :
                            ''
                        }
                        <h2 className="subtitle">{beneficio?.descuento}</h2>
                        <h4 className="caption">
                            <ReadMore>{beneficio?.descripcion}</ReadMore>
                        </h4>
                        <div className="sponsor">
                            <span>Entregado por:</span>
                            {imageConvenio}
                        </div>
                        <div className="button-container">
                        <button className="btn btn--icon" onClick={handleGiveItToMe}>
                            <i
                                className="ico-like">
                            </i>
                            <span>Quiero usar este beneficio</span>
                        </button>
                            
                        </div>
                    </div>
                    <div className="dialog__indicator">
                        <i className="ico-chevron-doble-down">
                        </i>
                    </div>
                    <div className="dialog__legal">
                        <div className="caption">
                            <strong>Condiciones de uso:</strong>
                            <ReadMore>{beneficio?.condiciones}</ReadMore>
                        </div>
                    </div>
                </div>
            </div>
            <div className="backdrop"></div>
        </main>
    );
}
export default Beneficio;
