import {Navigate} from "react-router-dom";
import TokenService from "../services/token.service";
import React from "react";
import {useLiveQuery} from "dexie-react-hooks";

const PrivateWrapper = ({children}: { children: JSX.Element }) => {
    const auth = useLiveQuery(() => TokenService.getUser());
    if (!auth) return null;
    return auth?.jwt ? <> {children} </> : <Navigate to="/" replace/>;
};

export default PrivateWrapper;