import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/styles/style.css';
import App from './App';
import {BrowserRouter} from "react-router-dom";
import {GoogleOAuthProvider} from "@react-oauth/google";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <BrowserRouter>
        <GoogleOAuthProvider
            clientId="244659542285-claub3pash15eqpiv699v8hsqu3bm7qv.apps.googleusercontent.com">
        <App/>
        </GoogleOAuthProvider>
    </BrowserRouter>
);
