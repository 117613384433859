import React from "react";

type BadgeProps = {
    id?: number,
    idb?: string,
    nombre?: string,
    activo: boolean,
    class?: string,
    icon?: string,
    onClick?: React.MouseEventHandler<HTMLDivElement>
};

export const Badge: React.FC<BadgeProps> = (props) => {
    return (
        <div id={props.idb} data-id={props.id}
             className={`badge badge--likes ` + (props.activo ? 'is-active' : '') + (props.class ?? '')}
             onClick={props.onClick}>
            <i className={props.icon ?? 'ico-heart'}></i>
            {props.nombre ? <b>{props.nombre}</b> : ''}
        </div>
    );
}