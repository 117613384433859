import '../assets/styles/loading.css';
import React, {HTMLAttributeReferrerPolicy, useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Badge} from "./Badge";
import {buscarBeneficios, getTags} from "../services/empresas.service";
import {responseToast} from "../common/helpers";
import Tag from "../types/tags.type";
import Loading from "./Loading";
import Beneficio from "../types/beneficios.type";
import axios from "axios";
import BeneficioCard from "./BeneficioCard";

const Buscador: React.FC = () => {
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState<boolean>(false);
    const [loadedResults, setLoadedResults] = useState<boolean>(true);
    const [listaTags, setListaTags] = useState<Tag[]>([]);
    const [listaBeneficios, setListaBeneficios] = useState<Beneficio[]>([]);
    const [busqueda, setBusqueda] = useState('');
    const [tagsBusqueda, setTagsBusqueda] = useState<number[]>([]);

    const toggleBadge = (event: React.MouseEvent) => {
        const target = event.currentTarget;
        target.classList.toggle('is-active');
        const id = parseInt(target.getAttribute('data-id') ?? '');
        if (event.currentTarget.classList.contains('is-active')) {
            setTagsBusqueda([...tagsBusqueda, id]);
        } else {
            setTagsBusqueda([...tagsBusqueda.filter(val => val !== id)]);
        }
    }
    const handleInput = (e: any) => {
        const value = e.target.value;
        setBusqueda(value);
    }
    const emailInput = useRef<HTMLInputElement>(null);

    useEffect(() => {
        getTags().then((resp) => {
                setListaTags(resp)
                setLoaded(true);
            },
            (error) => {
                setLoaded(true);
                responseToast(error);
            });      
    }, []);

    useEffect(() => {
        if(busqueda.length > 0 || tagsBusqueda.length > 0){
            setLoadedResults(false)
            const source = axios.CancelToken.source()
            const timeoutId = setTimeout(() => {
                buscarBeneficios(source, busqueda, tagsBusqueda).then(
                    (resp) => {
                        setLoadedResults(true)
                        setListaBeneficios(resp.data)
                    },
                    (error) => {
                        setLoadedResults(true)
                        if (typeof error !== undefined) responseToast(error);
                    });
            }, 800);
            return () => {
                setLoadedResults(true)
                source.cancel();
                clearTimeout(timeoutId)
            };
        }      
    }, [busqueda, tagsBusqueda]);
    
    useEffect(()=>{
        if (emailInput.current && loaded) {
            emailInput.current.focus();
        }
    },[loaded]);

    return (
        <>
            <Loading isLoaded={loaded}/>
            <main role="main" className={'direction-column'}>
                <section className="w100 search-container">
                    <div className="search-top">
                        <div className="form form--search">
                            <div className="form__field">
                                <input
                                    ref={emailInput}
                                    defaultValue={busqueda}
                                    onKeyUp={handleInput}
                                    autoComplete={'off'}
                                    className="input input--search" type="text"
                                    placeholder="Busca beneficios"/>
                            </div>
                        </div>
                        <button onClick={() => {
                            navigate(-1)
                        }}><i className="ico-close"></i></button>
                    </div>
                    <div className="results">
                        <div className="badges mb-1">
                            {
                                listaTags.map((item, index) => {
                                    return (
                                        <Badge
                                            id={item.id}
                                            key={'tag_' + item.nombre + index}
                                            nombre={item.nombre}
                                            activo={false}
                                            onClick={toggleBadge}
                                        />
                                    );
                                })
                            }
                        </div>
                    </div>

                </section>
                <section className="page page--benefits search-result block block--round">
                    <Loading
                        type={2}
                        isLoaded={loadedResults}
                        width={1}
                        extraClass={'block--round'}
                    />
                    <div className="benefits mb-1">
                       
                        {
                            listaBeneficios.length > 0 ?
                                listaBeneficios.map((item) => {
                                    return <BeneficioCard key={'beneficio-'+item.id} beneficio={item}/>;
                                })
                                : (
                                    busqueda.length > 0 || tagsBusqueda.length > 0 ?
                                        <span>No se encontraron beneficios con estos parámetros</span> : <p className="m-auto">¡Ingresa tu búsqueda y/o selecciona algún Tag!</p>
                                )

                        }
                    </div>
                </section>
                <div className="backdrop"></div>
            </main>
        </>
    );
}

export default Buscador;