import Dexie, {Table} from 'dexie';
import User from '../types/user.type';

export class UserManagementDatabase extends Dexie {
    user!: Table<User>;

    constructor() {
        super('userManagementDatabase');
        this.version(1).stores({
            user: '++id, jwt, data'
        });
    }
}

export const db = new UserManagementDatabase();