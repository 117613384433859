import React, {useEffect, useState} from 'react';

import {useNavigate, useParams} from "react-router-dom";
import Loading from "./Loading";
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css";
import {getBeneficios, getCategorias} from "../services/empresas.service";
import {responseToast} from "../common/helpers";
import TokenService from "../services/token.service";
import Beneficios from "../types/beneficios.type";
import Categorias from "../types/categorias.type";
import {base64StringToBlob, createObjectURL} from "blob-util";
import Navbar from "./Navbar";
import BeneficioCard from "./BeneficioCard";

const Categoria: React.FC = () => {
    const [loaded, setLoaded] = useState<boolean>(false);
    const [beneficios, setBeneficios] = useState<Beneficios[]>([]);
    const [categoria, setCategoria] = useState<Categorias>();
    const [listaCategorias, setListaCategoria] = useState<Categorias[]>();
    const navigate = useNavigate();
    const {id} = useParams();
    const [idCategoria, setIdCategoria] = useState(id);
    const [canShare, setCanShare] = React.useState<any | undefined>(undefined);

    const handleBack = () => {
        navigate(-1);
    };

    const handleShare = () => {
        try {
            navigator.share({
                title: 'Kandy.cl',
                text: 'Mira esta categoría de la APP Kandy'
            });
        } catch (error) {
            console.error(error)
        }
    };

    let imageCategoria;
    if (categoria?.imagen != null && categoria?.imagen.length > 0) {
        let blob = base64StringToBlob(categoria?.imagen);
        const blobUrl = createObjectURL(blob);
        imageCategoria = <img className={'letterhead__cover'} src={blobUrl} alt={categoria.nombre}/>
    } else {
        imageCategoria = '';
    }

    useEffect(() => {
        if (id === null || id === undefined) {
            navigate('/');
        }
        setLoaded(false);
        setCanShare(navigator['share'])
        const getBeneficiosPorCategoria = getBeneficios( null, null, null, null, parseInt(id ?? '0')).then((data) => {
                return data;
            },
            (error) => {
                responseToast(error);
                return [];
            });
        const getInfoCategoria = getCategorias( parseInt(id ?? '0')).then((data) => {
                return data;
            },
            (error) => {
                responseToast(error);
                return [];
            });
        const getAllCategoria = getCategorias().then((data) => {
                return data;
            },
            (error) => {
                responseToast(error);
                return [];
            });

        Promise.all([getBeneficiosPorCategoria, getInfoCategoria, getAllCategoria]).then(data => {
            setBeneficios(data[0]);
            setCategoria(data[1]);
            setListaCategoria(data[2]);
            setLoaded(true);
        })
    }, []);
    return (
        <>
            <Loading isLoaded={loaded}/>
            <Navbar/>
            <main role="main" className={'ai-fs'}>
                <section className="page page--benefits">
                    <div className="letterhead letterhead--default letterhead--column letterhead--back">
                        <div className="letterhead__actions">
                            <button className="action" onClick={handleBack}>
                                <i className="ico-back-circle"></i>
                            </button>
                            {
                                canShare ?
                                    <button className="action" onClick={handleShare}>
                                        <i className="ico-upload">
                                        </i>
                                    </button>
                                    : ''
                            }
                        </div>
                        <div className="launch">
                            <i className="ico-commerce"></i>
                            <h1 className="title">{categoria?.nombre}</h1>
                        </div>
                        {imageCategoria}
                    </div>
                    <div className="block block--round">
                        <div className="lead text-center">Tenemos {beneficios.length} beneficios para ti</div>
                        <div className="filters">
                            <Swiper
                                slidesPerView={'auto'}
                                spaceBetween={16}
                            >
                                <SwiperSlide
                                    role={'group'}
                                >
                                    <div className="tag"><i className="ico-filters"></i><span>Filtros</span></div>
                                </SwiperSlide>
                                {
                                    listaCategorias?.map((item) => {
                                        return (
                                            <SwiperSlide
                                                key={"categoria_" + item.nombre}
                                            >
                                                <a className={'no-decoration'} href={"/categorias/" + item.id}>
                                                    <div
                                                        className={"tag " + (item.id == idCategoria ? 'is-active' : '')}>{item.nombre}</div>
                                                </a>
                                            </SwiperSlide>
                                        );
                                    })
                                }
                            </Swiper>
                        </div>
                        <div className="benefits mb-1">
                            {beneficios.map((item) => {
                                    return <BeneficioCard key={'beneficio-'+item.id} beneficio={item}/>
                                }
                            )}
                        </div>
                    </div>
                </section>
            </main>
        </>
    );
}
export default Categoria;
