import React, {useEffect, useState} from 'react';

import {useNavigate, useParams} from "react-router-dom";
import Loading from "./Loading";
import "swiper/css";
import {getBeneficios, getComercios} from "../services/empresas.service";
import {responseToast} from "../common/helpers";
import TokenService from "../services/token.service";
import Beneficios from "../types/beneficios.type";
import {base64StringToBlob, createObjectURL} from "blob-util";
import {StoreOutlined} from "@mui/icons-material";
import Navbar from "./Navbar";
import Comercios from "../types/comercios.type";
import BeneficioCard from "./BeneficioCard";

const Comercio: React.FC = () => {
    const [loaded, setLoaded] = useState<boolean>(false);
    const [beneficios, setBeneficios] = useState<Beneficios[]>([]);
    const [comercio, setComercio] = useState<Comercios>();
    const navigate = useNavigate();
    const {id} = useParams();
    const [idComercio, setIdComercio] = useState(id);

    const handleBack = () => {
        navigate(-1);
    };

    let imageComercio;
    if (comercio?.imagen != null && comercio?.imagen.length > 0) {
        let blob = base64StringToBlob(comercio?.imagen);
        const blobUrl = createObjectURL(blob);
        imageComercio = <img src={blobUrl} alt={comercio.nombre}/>
    } else {
        imageComercio = <StoreOutlined fontSize='inherit'/>;
    }

    useEffect(() => {
        if (id === null || id === undefined) {
            navigate('/');
        }
        setLoaded(false);
        const getBeneficiosPorComercio = getBeneficios( null, null, parseInt(id ?? '0')).then((data) => {
                return data;
            },
            (error) => {
                responseToast(error);
                return [];
            });
        const getInfoComercio = getComercios(null, parseInt(id ?? '0')).then((data) => {
                return data;
            },
            (error) => {
                responseToast(error);
                return [];
            });

        Promise.all([getBeneficiosPorComercio, getInfoComercio]).then(data => {
            setBeneficios(data[0]);
            setComercio(data[1]);
            setLoaded(true);
        })
    }, []);
    return (
        <>
            <Loading isLoaded={loaded}/>
            <Navbar/>
            <main role="main" className={'ai-fs'}>
                <section className="page page--benefits">
                    <div className="letterhead letterhead--column">
                        <div className="letterhead__actions">
                            <button className="action" onClick={handleBack}>
                                <i className="ico-back-circle"></i>
                            </button>
                            <a className="action" href="/"><i className="ico-upload"></i></a></div>
                        <div className="launch">
                            <div className={'store'}>
                                {imageComercio}
                            </div>
                            <p className="lead">Estos son todos los tus beneficios para disfrutar
                                en <b>{comercio?.nombre}</b></p>
                        </div>
                    </div>
                    <div className="block block--round">
                        <div className="lead text-center mb-1">Tenemos {beneficios.length ?? 0} beneficios para ti</div>
                        <div className="benefits mb-1">
                            {beneficios.map((item) => {
                                return <BeneficioCard key={'beneficio-'+item.id} beneficio={item}/>
                            })}
                        </div>
                    </div>
                </section>
            </main>
        </>
    );
}
export default Comercio;
